import { CustomerData } from 'src/types/CustomerData';
import { jobTypeMap } from '../data/jobTypeMap';
import { CreateClaimRequestBody } from '../types/CreateClaimRequestBody';
import { FormState, Reattend } from '../types/Form';

interface MapFormToRequestBodyParams {
    formState: Partial<FormState>;
    userMode: string;
    relatedJob?: string | null;
    customerData: CustomerData;
    reattend?: Reattend;
}

export const mapFormToRequestBody = ({
    formState,
    relatedJob,
    customerData,
    reattend,
    userMode,
}: MapFormToRequestBodyParams): CreateClaimRequestBody => {
    const mappedJobType = (formState.jobType && jobTypeMap[formState.jobType]) || formState.jobType;

    const requiredFields: (keyof CreateClaimRequestBody)[] = [
        'vulnerable',
        'firstName',
        'lastName',
        'customerFacingId',
        'jobType',
        'dayOneDate',
        'dayOneTime',
        'dayTwoDate',
        'dayTwoTime',
        'dayThreeDate',
        'dayThreeTime',
        'email',
        'postcode',
        'description',
        'contractId',
        'userMode',
        'sk_account_id',
        'urgency',
    ];

    const allowedKeys: (keyof CreateClaimRequestBody)[] = [
        'contribution',
        'boilerMaker',
        'boilerModel',
        'boilerFaultCode',
        'problemType',
        'reattend',
        'boilerAge',
        'parking',
        'billingFullName',
        'paymentId',
        'visitType',
        'relatedJob',
        'stripe_amount',
        'gasLeak',
        'gasMains',
        'gasHeat',
        'boilerIssue',
        'plumbingIssue',
        'heatingProblemRelateTo',
        'heatingLeakVisibility',
        'plumbingToiletType',
        'plumbingToiletVisibleLeak',
        'plumbingToiletFlushing',
        'plumbingToiletBlockage',
        'drainsProblemRelateTo',
        'affectedWindowInMainHouse',
        'externalWindow',
        'insecureProperty',
        'affectedLockInMainHouse',
        'lockedOutFromProperty',
        'lockOnExternalWindowOrDoor',
        'doorsIssueArea',
        'doorsTypeOf',
        'doorsElectric',
        'doorsSecureBuilding',
        'pestsType',
        'pestsMiceInsideHome',
        'pestsWaspsInsideProperty',
        'electricIssue',
        'electricFailure',
        'electricOutdoorAbove3m',
        'applianceMaker',
        'applianceModel',
        'applianceFaultCode',
        'applianceSerialNumber',
        'applianceAge',
        'applianceId',
        'appliancePurchaseDate',
        'lastBoilerService',
    ];

    const requestBody: Partial<CreateClaimRequestBody> = {
        ...Object.fromEntries(
            Object.entries(formState).filter(
                ([key, value]) =>
                    (allowedKeys.includes(key as keyof CreateClaimRequestBody) &&
                        value !== undefined &&
                        value !== null) ||
                    requiredFields.includes(key as keyof CreateClaimRequestBody)
            )
        ),
        jobType: mappedJobType,
        email: customerData.email,
        customerFacingId: formState.customerFacingId,
        reattend,
        firstName: customerData.firstName,
        lastName: customerData.lastName,
        userMode,
        parking: 'Yes',
    };

    if (relatedJob) {
        requestBody.relatedJob = relatedJob;
    }
    if (formState.reattend === Reattend.YES) {
        requestBody.visitType = 'Reattend';
    }

    return requestBody as CreateClaimRequestBody;
};
