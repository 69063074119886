import { FormState } from '../types/Form';

export const getLastBoilerServiceDate = (values: Partial<FormState>) => {
    let lastBoilerService;

    if (values.lastBoilerServiceYear !== undefined && values.lastBoilerServiceMonth !== undefined) {
        lastBoilerService = `${values.lastBoilerServiceYear?.name}-${
            values.lastBoilerServiceMonth?.id ? values.lastBoilerServiceMonth?.id : ''
        }`;
    }
    return lastBoilerService || null;
};
