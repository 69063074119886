import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { QueryFunction } from 'react-query';
import { CONTRACT_ENDPOINT } from 'src/constants';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';
import { SkeduloJob } from 'src/types/Skedulo';
import { isPandoraErrorResponse } from 'src/types/pandora';
import { getMandatoryEnvVar } from 'src/utils/env';
import { getSkeduloJobs } from '../../../api/skedulo';
import {
    getAuthenticatedUser,
    getTokenFromAuthenticatedUser,
} from '../../login/utils/authenticatedUser';
import { Contract } from '../types/Contract';
import { getContractResponseSchema } from './getContract.types';
import { getPandoraJob } from './getPandoraJob';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const CONTRACT_ID_REQUIRED = 'Contract ID required';

export type GetContractQueryKey = [string, string];

export const getContractById: QueryFunction<Contract, GetContractQueryKey> = async ({
    queryKey,
}) => {
    const user = await getAuthenticatedUser();
    const token = getTokenFromAuthenticatedUser(user);

    const [, contractId] = queryKey;

    if (!contractId) {
        throw new Error(CONTRACT_ID_REQUIRED);
    }

    const getContractsUrl = `${API_BASE_URL}/${CONTRACT_ENDPOINT}/v1/contracts/${contractId}`;

    try {
        const response = await fetch(getContractsUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        const data = await response.json();

        const ajv = new Ajv();
        addFormats(ajv); // add formats for date-time format
        const validate = ajv.compile(getContractResponseSchema);
        const valid = validate(data);

        if (!valid) {
            const error = new Error(
                `Failed to validate getContract response. Error in ${JSON.stringify(
                    validate.errors?.[0].schemaPath
                )} - ${JSON.stringify(validate.errors?.[0].params)}`
            );
            await captureSentryError(error, {
                message: `Error validating contract`,
                customerFacingId: data.contractId || '',
            });
        }

        if (isPandoraErrorResponse(data)) {
            throw new Error(`Error message from api: ${data.message}`);
        }
        if (data.data.length === 0) {
            throw new Error('No contract found');
        }

        const contract = data.data[0];
        const skeduloJobsData = await getSkeduloJobs(contract.contractCustomerFacingId);

        const combinedJobs = await Promise.all(
            (skeduloJobsData.data?.jobs.edges as SkeduloJob[]).map(async (job: SkeduloJob) => {
                const pandoraJob = await getPandoraJob(token, job.node.UID);
                if (pandoraJob instanceof Error) {
                    throw pandoraJob;
                }
                return {
                    node: {
                        ...job.node,
                        pandoraClaimStatus: pandoraJob.claim_status_name,
                        abortReason: pandoraJob.sk_abort_reason,
                    },
                };
            })
        );

        return { ...contract, jobs: combinedJobs };
    } catch (err) {
        const errorMessage = `Failed to get contract - ${err.message}`;

        // eslint-disable-next-line no-console
        console.error(errorMessage, err);
        await captureSentryError(err, {
            message: errorMessage,
        });
        throw new Error(errorMessage);
    }
};
