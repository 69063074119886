import { useQuery } from 'react-query';
import { Account } from 'src/features/login/api/account.types';
import { getAccount, mapGetAccountResponseToAccount } from '../features/login/api/account';

export const useAccountQuery = () => {
    const { isLoading, isError, error, data } = useQuery<Account, Error, any>(
        ['account'],
        getAccount
    );

    return {
        isLoading,
        isError,
        error,
        data: mapGetAccountResponseToAccount(data),
    };
};
