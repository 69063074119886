import { Property } from 'src/features/newClaim/api/getContract.types';
import { ArrowRight } from '../../../images';
import styles from './index.module.scss';

interface Props {
    properties: Property[];
    onSelect: (propertyId: number) => void;
}

const ContractsList = ({ properties, onSelect }: Props) => {
    return (
        <div className={styles.propertiesList}>
            {properties.map((property, index) => (
                <button
                    type="button"
                    key={property.id}
                    onClick={() => onSelect(property.id)}
                    data-testid={`contract-select-address-button-${index}`}
                    className={styles.contractContainer}
                >
                    <div>
                        {property.address.line1}, {property.town}, {property.postcode}
                    </div>
                    <ArrowRight />
                </button>
            ))}
        </div>
    );
};

export default ContractsList;
