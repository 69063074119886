import { Config } from 'src/features/newClaim/types/Question';
import { AreaOfIssue } from 'src/types/Job';
import { FormState } from '../../types/Form';
import { QuestionStageValues } from '../../types/Question';
import DropdownCalendar from '../DropdownCalendar';

export interface Props {
    name: string;
    title?: string;
    description?: string;
    formState: Partial<FormState>;
    setState: (state: QuestionStageValues) => void;
    error?: string;
    config?: Config;
}

const DropdownCalendarConditional = ({
    name: _name,
    title: _title,
    description: _description,
    formState: _formState,
    setState: _setState,
    error: _error,
    config: _config,
}: Props) => {
    return (
        <>
            {(_formState.jobType === AreaOfIssue.BOILER ||
                _formState.jobType === AreaOfIssue.HEATING) && (
                <DropdownCalendar
                    name={_name}
                    title={_title}
                    description={_description}
                    setState={_setState}
                    formState={_formState}
                    error={_error}
                    config={_config}
                />
            )}
        </>
    );
};

export default DropdownCalendarConditional;
