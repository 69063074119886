import { isValid } from 'date-fns';
import Button from 'src/components/Button';
import { HOMETREE_PHONE_NUMBER } from 'src/constants';
import { appliancesTypes } from 'src/types/Appliances';
import { CustomerData } from 'src/types/CustomerData';
import { AreaOfIssue, JobType } from 'src/types/Job';
import { ProductEnum } from 'src/types/ProductEnum';
import { SkeduloJob } from 'src/types/Skedulo';
import { determineJobUrgency } from 'src/utils/jobs';
import { jobTypeMap } from '../data/jobTypeMap';
import { FormState, Reattend } from '../types/Form';
import { QuestionStage, QuestionType } from '../types/Question';
import { determineContributionAmount } from '../utils/determineContributionAmount';
import { determinePaymentNeeded } from '../utils/determinePaymentNeeded';
import { getLastBoilerServiceDate } from '../utils/getLastBoilerServiceDate';
import { hasOpenJobSameType } from '../utils/hasOpenJobSameType';
import { isLastServiceDateInvalid } from '../utils/isLastServiceDateInvalid';
import { appliancesQuestions } from './appliancesQuestions';
import { boilerQuestions } from './boilerQuestions';
import { doorsQuestions } from './doorsQuestions';
import { drainsQuestions } from './drainsQuestions';
import { electricQuestions } from './electricQuestions';
import { heatingQuestions } from './heatingQuestions';
import styles from './index.module.scss';
import { locksQuestions } from './locksQuestions';
import { pestsQuestions } from './pestsQuestions';
import { plumbingQuestions } from './plumbingQuestions';
import { windowsQuestions } from './windowsQuestions';

export const questionStages = (
    customerData: CustomerData,
    formState: Partial<FormState>,
    reattend: Reattend
): QuestionStage[] => {
    const isAppliance = !!appliancesTypes[formState.jobType || ''];
    const applianceContribution =
        isAppliance && (formState.startDate || customerData.startDate)
            ? determineContributionAmount({
                  isRenewal: formState.isRenewal || customerData.isRenewal,
                  contractStartDate: formState.startDate || customerData.startDate,
                  applianceAge: formState.applianceAge,
              })
            : 0;

    return [
        // Import job type specific questions
        ...boilerQuestions(formState),
        ...heatingQuestions(formState),
        ...electricQuestions(formState),
        ...plumbingQuestions(formState),
        ...drainsQuestions(formState),
        ...windowsQuestions(),
        ...locksQuestions(),
        ...doorsQuestions(),
        ...pestsQuestions(),
        ...appliancesQuestions(formState),

        // Shared questions
        {
            handle: 'problemAreaNew',
            questions: [
                {
                    name: 'jobType',
                    title: 'Repair Details',
                    description: 'Which issue would you like to report?',
                    type: QuestionType.BUBBLERADIO,
                    isRequired: true,
                    config: {
                        options: [
                            {
                                copy: 'Boiler',
                                value: AreaOfIssue.BOILER,
                                subCopy:
                                    'Issues with your boiler including any thermostats, programmers, valves or pumps.',
                            },
                            {
                                copy: 'Heating',
                                value: AreaOfIssue.HEATING,
                                subCopy:
                                    'Issues with your radiators, cylinders, or valves related to your central heating.',
                            },
                            {
                                copy: 'Electric',
                                value: AreaOfIssue.ELECTRIC,
                                subCopy:
                                    'Issues with your main electrical system, including fuse boxes, light fittings and light switches.',
                            },
                            {
                                copy: 'Plumbing',
                                value: AreaOfIssue.PLUMBING,
                                subCopy:
                                    'Issues with your plumbing like burst pipes or a broken toilet flush.',
                            },
                            {
                                copy: 'Drainage',
                                value: AreaOfIssue.DRAINAGE,
                                subCopy: 'Issues with your drains like blocked toilets.',
                            },
                            {
                                copy: 'Windows',
                                value: AreaOfIssue.WINDOWS,
                                subCopy: 'Issues related to locked or broken external windows.',
                            },
                            {
                                copy: 'Locks',
                                value: AreaOfIssue.LOCKS,
                                subCopy:
                                    'Issues with gaining access to your house due to lost, stolen or broken keys and locks.',
                            },
                            {
                                copy: 'Pests',
                                value: AreaOfIssue.PESTS,
                                subCopy: 'Infestation of mice, rats, wasps and hornets.',
                            },
                            {
                                copy: 'Doors',
                                value: AreaOfIssue.DOORS,
                                subCopy: 'Issues related to locked or broken external doors.',
                            },
                            {
                                copy: 'Appliances',
                                value: AreaOfIssue.APPLIANCES,
                                subCopy: 'Issues related to appliances you have covered with us.',
                                subLinkCopy: 'Register interest',
                                subLinkPath: 'https://zcm8o7t0fr2.typeform.com/to/xwN4qglx',
                                subLinkExternal: true,
                            },
                        ],
                        enabledOptions:
                            customerData?.allIncludedProducts || customerData?.includedProducts,
                        packageConfig:
                            customerData?.allPackageDetails || customerData.packageDetails,
                    },
                    data: customerData,
                },
            ],
            onSubmit: (values) =>
                ({
                    jobType: values.jobType,
                    description: values.description,
                    contractId: values.contractId,
                    packageDetails: values.packageDetails,
                    startDate: values.startDate,
                    isRenewal: values.isRenewal,
                    contribution: values.contribution,
                    billingType: values.billingType,
                    appliances: values.appliances,
                    sk_account_id: values.sk_account_id,
                    jobs: values.jobs as SkeduloJob[],
                    claimLimitReached: values.claimLimitReached,
                    isOilPackage: values.isOilPackage,
                    packageId: values.packageId,
                    postcode: values.postcode,
                    includedProducts: values.includedProducts,
                    customerFacingId: values.customerFacingId,
                } as Partial<FormState>),
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (typeof values.jobType !== 'string') {
                            return 'policyNotCovered';
                        }

                        if (
                            values?.jobs?.length &&
                            hasOpenJobSameType(values?.jobs as SkeduloJob[], values.jobType)
                        ) {
                            return 'sameJobTypeAlreadyOpen';
                        }

                        if (
                            values.jobType &&
                            typeof values.jobType === 'string' &&
                            values?.includedProducts?.includes(values.jobType as ProductEnum)
                        ) {
                            if (values.jobType === AreaOfIssue.APPLIANCES) {
                                return 'appliancesGroup1';
                            }

                            return 'description';
                        }

                        return 'policyNotCovered';
                    },
                },
            ],
        },
        {
            handle: 'problemArea',
            questions: [
                {
                    name: 'selfFixText',
                    type: QuestionType.TEXT,
                    isRequired: true,
                    config: {
                        extraCopy: (
                            <>
                                <h2>Request a Repair</h2>
                                <h4>
                                    Many problems can be quickly solved by yourself without an
                                    engineer visit
                                </h4>
                                <p>
                                    We have created self-help guides for the most common issues that
                                    our customers face. Before contacting us, we recommend giving
                                    these a try and you could be up and running in minutes without
                                    the need to wait for an engineer.
                                </p>
                                <div className={styles.requestBtn}>
                                    <Button
                                        variant="primary"
                                        onClick={() =>
                                            window.open(
                                                'https://www.hometree.co.uk/energy-advice/boiler-cover/self-help-guide/',
                                                '_blank',
                                                'noopener noreferrer'
                                            )
                                        }
                                    >
                                        Try these quick fixes
                                    </Button>
                                </div>
                                <h4>
                                    Please answer the following questions below and we will be in
                                    touch very soon to confirm your repair booking.
                                </h4>
                            </>
                        ),
                    },
                },
                {
                    name: 'jobType',
                    description: 'What area of the home has the issue?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Boiler', value: AreaOfIssue.BOILER },
                            { copy: 'Heating', value: AreaOfIssue.HEATING },
                            { copy: 'Electric', value: AreaOfIssue.ELECTRIC },
                            { copy: 'Plumbing', value: AreaOfIssue.PLUMBING },
                            { copy: 'Drainage', value: AreaOfIssue.DRAINAGE },
                            { copy: 'Windows', value: AreaOfIssue.WINDOWS },
                            { copy: 'Locks', value: AreaOfIssue.LOCKS },
                            { copy: 'Pests', value: AreaOfIssue.PESTS },
                            { copy: 'Doors', value: AreaOfIssue.DOORS },
                        ],
                    },
                },
                {
                    name: 'description',
                    description: `Please describe the fault fully`,
                    type: QuestionType.TEXTAREA,
                    config: {
                        placeholder:
                            'Please detail when the issue began, where the problem is and if there are any accessbility factors we should be aware of...',
                        text: {
                            fieldLength: 230,
                        },
                    },
                    isRequired: true,
                },
            ],
            onSubmit: (values) =>
                ({
                    jobType: values.jobType,
                    description: values.description,
                } as Partial<FormState>),
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (typeof values.jobType !== 'string') {
                            return 'policyNotCovered';
                        }

                        if (
                            formState?.jobs?.length &&
                            hasOpenJobSameType(formState?.jobs as SkeduloJob[], values.jobType)
                        ) {
                            return 'sameJobTypeAlreadyOpen';
                        }

                        if (
                            values.jobType &&
                            typeof values.jobType === 'string' &&
                            formState?.includedProducts?.includes(values.jobType as ProductEnum)
                        ) {
                            switch (values.jobType) {
                                case AreaOfIssue.BOILER:
                                    return 'boilerGroup1';
                                case AreaOfIssue.HEATING:
                                    return 'heatingGroup1';
                                case AreaOfIssue.ELECTRIC:
                                    return 'electricGroup1';
                                case AreaOfIssue.PLUMBING:
                                    return 'plumbingGroup1';
                                case AreaOfIssue.DRAINAGE:
                                    return 'drainageGroup1';
                                case AreaOfIssue.WINDOWS:
                                    return 'windowsGroup1';
                                case AreaOfIssue.LOCKS:
                                    return 'locksGroup1';
                                case AreaOfIssue.DOORS:
                                    return 'doorsGroup1';
                                case AreaOfIssue.PESTS:
                                    return 'pestsGroup1';
                                default:
                                    return 'policyNotCovered';
                            }
                        }

                        return 'policyNotCovered';
                    },
                },
            ],
        },
        {
            handle: 'description',
            questions: [
                {
                    name: 'description',
                    description: `Please describe the issue`,
                    type: QuestionType.TEXTAREA,
                    config: {
                        placeholder:
                            'Please detail when the issue began, where the problem is and if there are any accessbility factors we should be aware of...',
                        text: {
                            fieldLength: 230,
                        },
                        otherTitle: 'Provide as much detail as possible',
                        extraCopy: (
                            <>
                                <h4>
                                    Many problems can be quickly solved by yourself without an
                                    engineer visit
                                </h4>
                                <p>
                                    We have created self-help guides for the most common issues that
                                    our customers face. Before contacting us, we recommend giving
                                    these a try and you could be up and running in minutes without
                                    the need to wait for an engineer.
                                </p>
                                <br />
                                <div>
                                    <Button
                                        variant="primary"
                                        onClick={() =>
                                            window.open(
                                                'https://www.hometree.co.uk/energy-advice/boiler-cover/self-help-guide/',
                                                '_blank',
                                                'noopener noreferrer'
                                            )
                                        }
                                    >
                                        Try these quick fixes
                                    </Button>
                                </div>
                            </>
                        ),
                    },
                    isRequired: true,
                },
                {
                    name: 'lastBoilerService',
                    type: QuestionType.DROPDOWN_CALENDAR_CONDITIONAL,
                    description: 'Please select a date',
                    isRequired: true,
                    config: {
                        placeholder: 'Month',
                        secondPlaceholder: 'Year',
                        otherTitle:
                            'When was your last boiler service completed? (If uncertain choose N/A)',
                        amountOfYears: 10,
                    },
                },
            ],
            onSubmit: (values: Partial<FormState>) => {
                const areaOfIssueIsHeatingOrBoiler = AreaOfIssue.BOILER || AreaOfIssue.HEATING;
                const hasNoServiceDate =
                    (values.lastBoilerServiceYear === undefined ||
                        values.lastBoilerServiceMonth === undefined ||
                        values.lastBoilerServiceYear?.name === 'N/A' ||
                        values.lastBoilerServiceMonth?.name === 'N/A') &&
                    areaOfIssueIsHeatingOrBoiler;
                return {
                    description: values.description,
                    lastBoilerService:
                        hasNoServiceDate || !isValid(new Date(getLastBoilerServiceDate(values)))
                            ? null
                            : new Date(getLastBoilerServiceDate(values)).toISOString(),
                } as Partial<FormState>;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (_, formCurrentState: Partial<FormState>) => {
                        switch (formCurrentState.jobType) {
                            case AreaOfIssue.BOILER:
                                return 'boilerGroup1';
                            case AreaOfIssue.HEATING:
                                return 'heatingGroup1';
                            case AreaOfIssue.ELECTRIC:
                                return 'electricGroup1';
                            case AreaOfIssue.PLUMBING:
                                return 'plumbingGroup1';
                            case AreaOfIssue.DRAINAGE:
                                return 'drainageGroup1';
                            case AreaOfIssue.WINDOWS:
                                return 'windowsGroup1';
                            case AreaOfIssue.LOCKS:
                                return 'locksGroup1';
                            case AreaOfIssue.DOORS:
                                return 'doorsGroup1';
                            case AreaOfIssue.PESTS:
                                return 'pestsGroup1';
                            case AreaOfIssue.APPLIANCES:
                                return 'appliancesGroup1';
                            default:
                                return 'policyNotCovered';
                        }
                    },
                    isDisabled: (values: Partial<FormState>) => {
                        return !values.description || isLastServiceDateInvalid(values, formState);
                    },
                },
            ],
        },
        {
            handle: 'vulnerability',
            questions: [
                {
                    name: 'vulnerable',
                    description:
                        'Please let us know if there are vulnerable people at the property?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
            ],
            onSubmit: (values: Partial<FormState>, submitFormState) => ({
                vulnerable: values.vulnerable,
                urgency: determineJobUrgency(
                    String(values.vulnerable),
                    submitFormState.problemType || '',
                    submitFormState.insecureProperty || '',
                    submitFormState.lockedOutFromProperty || '',
                    submitFormState.doorsSecureBuilding || '',
                    submitFormState.jobType as JobType,
                    submitFormState?.applianceUrgencyIssue || ''
                ),
            }),
            options: [
                {
                    copy: 'Next',
                    nextQuestion: 'chooseDate',
                },
            ],
        },

        {
            handle: 'chooseDate',
            questions: [
                {
                    name: 'calendar',
                    description: 'Please let us know when you are free for the repair',
                    type: QuestionType.CALENDAR,
                    isRequired: true,
                    config: {},
                },
            ],
            onSubmit: (values) => {
                return {
                    dayOneDate: values.dayOneDate,
                    dayOneTime: values.dayOneTime,
                    dayTwoDate: values.dayTwoDate,
                    dayTwoTime: values.dayTwoTime,
                    dayThreeDate: values.dayThreeDate,
                    dayThreeTime: values.dayThreeTime,
                } as Partial<FormState>;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: determinePaymentNeeded(
                        formState?.contribution || applianceContribution,
                        reattend
                    ),
                },
            ],
        },
        {
            handle: 'paymentDetails',
            questions: [
                {
                    name: 'stripe',
                    description: 'Pay your contribution fee',
                    type: QuestionType.PAYMENT,
                    contribution: formState?.contribution || applianceContribution,
                    isRequired: false,
                    config: {
                        otherTitle: formState?.packageDetails?.requiresInsurerApproval
                            ? 'Pay your excess fee'
                            : undefined,
                    },
                },
            ],
            onSubmit: (values) =>
                ({
                    paymentId: values.paymentId,
                } as Partial<FormState>),
            config: {
                hideOptionsButtons: true,
            },
            options: [
                {
                    copy: 'Submit claim',
                    nextQuestion: 'submitClaim',
                },
            ],
        },
        {
            handle: 'submitClaim',
            questions: [
                {
                    name: 'submitClaim',
                    type: QuestionType.STAGE_LOADER,
                },
            ],
            onSubmit: () => ({} as Partial<FormState>),
            config: {},
        },
        {
            handle: 'policyNotCovered',
            questions: [
                {
                    name: 'failed',
                    description: `It looks like your current plan doesn’t cover the fault you have described.`,
                    type: QuestionType.FAILED,
                    isRequired: true,
                    config: {
                        extraCopy: (
                            <span>
                                To upgrade your policy, please contact our team on&nbsp;
                                <a href={`tel:${HOMETREE_PHONE_NUMBER}`}>{HOMETREE_PHONE_NUMBER}</a>
                            </span>
                        ),
                    },
                },
            ],
            onSubmit: () => {},
            config: {},
        },
        {
            handle: 'lossOfServiceNotCovered',
            questions: [
                {
                    name: 'failed',
                    description: `Your current plan is an emergency cover policy. This means we can only send an engineer when there's a complete loss of service.`,
                    type: QuestionType.FAILED,
                    isRequired: true,
                    config: {
                        extraCopy: (
                            <span>
                                For more information on our products or to upgrade your policy
                                please contact&nbsp;
                                <a href={`tel:${HOMETREE_PHONE_NUMBER}`}>{HOMETREE_PHONE_NUMBER}</a>
                            </span>
                        ),
                    },
                },
            ],
            onSubmit: () => {},
            config: {},
        },
        {
            handle: 'lossOfServiceNotCoveredNoExtraCopy',
            questions: [
                {
                    name: 'failed',
                    type: QuestionType.FAILED,
                    isRequired: true,
                    config: {
                        otherTitle: `Your current plan is an emergency cover policy. As per the terms of the policy, we can only send an engineer when there's a complete loss of service.`,
                    },
                },
            ],
            onSubmit: () => {},
            config: {},
        },
        {
            handle: 'sameJobTypeAlreadyOpen',
            questions: [
                {
                    name: 'failed',
                    description: `We have already received your request to repair your ${
                        formState.jobType ? jobTypeMap[formState.jobType] : '"Not Specified"'
                    }`,
                    type: QuestionType.FAILED,
                    isRequired: true,
                    config: {
                        extraCopy: (
                            <span>
                                If you are having issues then call us on &nbsp;
                                <a href={`tel:${HOMETREE_PHONE_NUMBER}`}>{HOMETREE_PHONE_NUMBER}</a>
                            </span>
                        ),
                    },
                },
            ],
            onSubmit: () => {},
            config: {},
        },
        {
            handle: 'optionNotCovered',
            questions: [
                {
                    name: 'failed',
                    type: QuestionType.FAILED,
                    isRequired: true,
                },
            ],
            onSubmit: () => {},
            config: {},
        },
    ];
};
