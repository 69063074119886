import { UseMutationResult, useMutation } from 'react-query';
import { createClaim } from 'src/features/newClaim/api/createClaim';
import {
    CreateClaimRequestBody,
    CreateClaimResponse,
} from 'src/features/newClaim/types/CreateClaimRequestBody';

export const useCreateClaim = (): UseMutationResult<
    CreateClaimResponse,
    Error,
    CreateClaimRequestBody
> => {
    return useMutation<CreateClaimResponse, Error, CreateClaimRequestBody>(
        (data: CreateClaimRequestBody) => createClaim(data)
    );
};
