import { useMutation } from 'react-query';
import { EmbeddedLinkRequest } from '../types/EmbeddedLinkRequest';
import { postEmbeddedLink } from '../api/postEmbeddedLink';
import { Document } from '../api/getDocumentsSignRequired';

export const useCreateEmbeddedLink = (documents: Document[]) => {
    // react-query mutation
    // return isLoading, isError, error, data, ...
    return useMutation<{ embeddedLink: string }, Error, EmbeddedLinkRequest, unknown>(
        ['createEmbeddedLink'],
        (data: EmbeddedLinkRequest) => postEmbeddedLink(data, documents)
    );
};
