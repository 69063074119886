export enum ApplianceCategory {
    COOKING = 'Cooking',
    LAUNDRY = 'Laundry',
    REFRIGERATION = 'Refrigeration',
    OTHERS = 'Others',
}

export interface Appliance {
    id: number;
    contractId: number;
    pricePerMonthGbx: number;
    type: string;
    category: ApplianceCategory;
    make: string | null;
    purchasePriceGbx: number | null;
    purchaseRetailer: string;
    purchaseDate: string;
    modelNumber: string;
    serialNumber: string;
    dateCreated: string;
    dateModified: string;
    author: string;
}

export const appliancesTypes = {
    Dishwasher: 'Dishwasher',
    'Coffee Machine': 'Coffee Machine',
    Cooker: 'Cooker',
    Freezer: 'Freezer',
    Fridge: 'Fridge',
    'Fridge Freezer': 'Fridge Freezer',
    Hob: 'Hob',
    Microwave: 'Microwave',
    Television: 'Television',
    'Washer Dryer': 'Washer Dryer',
    'Washing Machine': 'Washing Machine',
    'Wine Cooler': 'Wine Cooler',
    'Tumble Dryer': 'Tumble Dryer',
    'Tumble Dryer Electric': 'Tumble Dryer Electric',
    'Hob Electric': 'Hob Electric',
    Oven: 'Oven',
    'Oven Electric': 'Oven Electric',
    'Cooker Electric': 'Cooker Electric',
    'Tumble Dryer Gas': 'Tumble Dryer Gas',
    'Hob Gas': 'Hob Gas',
    'Oven Gas': 'Oven Gas',
    'Cooker Gas': 'Cooker Gas',
    'Cooker Dual': 'Cooker Dual',
    'Satellite Box': 'Satellite Box',
    'Vacuum Cleaner': 'Vacuum Cleaner',
    'Cooker Hood': 'Cooker Hood',
};

export interface AppliancePayload {
    id: number;
    makeId?: number;
    serialNumber?: string;
    modelNumber?: string;
    lastModifiedBy?: string;
    type?: string;
    purchaseDate?: string;
}
