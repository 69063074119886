import { ChevronDown, ChevronUp } from '@HometreeEngineering/component-library';
import { useEffect, useRef, useState } from 'react';
import { Config } from 'src/features/newClaim/types/Question';
import styles from './index.module.scss';

export interface Props {
    options: { id: number | string; name: string }[];
    customStyle?: string;
    selectedOption: { id: number | null; name: string | null };
    setSelectedOption: Function;
    config?: Config;
    isDisabled?: boolean;
}

const InputDropdown = ({
    customStyle,
    options,
    selectedOption,
    setSelectedOption,
    config,
    isDisabled,
}: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredOptions, setFilteredOptions] = useState<
        { id: number | string; name: string }[] | null
    >(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const onOptionClicked = (option: { id: number | string; name: string }) => () => {
        setSelectedOption(option);
        setIsOpen(false);
        setSearchTerm('');
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
                setSearchTerm('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (options) {
            setFilteredOptions(options);
        }
    }, [options]);

    const handleChange = (e) => {
        setSearchTerm(e.target.value);
        const matchingOptions = options?.filter((option) =>
            option?.name?.toLowerCase().includes(e.target.value.toLowerCase())
        );

        setFilteredOptions(matchingOptions);
    };

    return (
        <div className={`${styles.main} ${customStyle || ''}`} ref={dropdownRef}>
            <div className={styles.dropdownContainer}>
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className={styles.button}
                    type="button"
                    data-testid="dropdown-button"
                    aria-haspopup="listbox"
                    aria-expanded={isOpen}
                    disabled={isDisabled || false}
                >
                    <div className={styles.dropdownFirstItem}>
                        <input
                            type="text"
                            className={`${styles.searchInput} ${
                                selectedOption?.name ? styles.edited : ''
                            }`}
                            placeholder={config?.placeholder}
                            value={isOpen ? searchTerm : selectedOption?.name || ''}
                            onChange={(e) => handleChange(e)}
                            data-testid="dropdown-search"
                            aria-label="Search options"
                            disabled={isDisabled || false}
                        />
                        {isOpen ? (
                            <span className={styles.icon}>
                                <ChevronUp />
                            </span>
                        ) : (
                            <span className={styles.icon}>
                                <ChevronDown />
                            </span>
                        )}
                    </div>
                </button>
                {isOpen && (
                    <div className={styles.dropdownContent}>
                        <ul className={styles.dropdownList}>
                            {filteredOptions?.map((option) => (
                                <li
                                    className={`${styles.listItem} ${
                                        option.name === selectedOption.name ? styles.selected : ''
                                    }`}
                                    key={option.id}
                                >
                                    <button
                                        onClick={onOptionClicked(option)}
                                        type="button"
                                        className={styles.listItemButton}
                                        data-testid="options"
                                    >
                                        <p>{option.name}</p>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InputDropdown;
