import { AreaOfIssue } from 'src/types/Job';
import { getLastBoilerServiceDate } from './getLastBoilerServiceDate';

export const isLastServiceDateInvalid = (values, formState) => {
    const areaOfIssueIsHeatingOrBoiler =
        formState.jobType === AreaOfIssue.BOILER || formState.jobType === AreaOfIssue.HEATING;
    if (
        (values.lastBoilerServiceMonth?.name === 'N/A' ||
            values.lastBoilerServiceYear?.name === 'N/A') &&
        areaOfIssueIsHeatingOrBoiler
    ) {
        return false;
    }
    const lastBoilerService = getLastBoilerServiceDate(values);

    const isDisabledBoiler =
        (!values.lastBoilerServiceMonth ||
            !values.lastBoilerServiceYear ||
            !lastBoilerService ||
            new Date(lastBoilerService) > new Date()) &&
        areaOfIssueIsHeatingOrBoiler;

    return isDisabledBoiler;
};
