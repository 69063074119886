import { ACCOUNT_ENDPOINT } from 'src/constants';
import {
    getAttributesFromAuthenticatedUser,
    getTokenFromAuthenticatedUser,
} from 'src/features/login';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';
import { getMandatoryEnvVar } from 'src/utils/env';

export interface Document {
    id: number;
    templateId: string;
    name?: string;
    displayName?: string;
}

export const getDocumentsSignRequired = async (user): Promise<Document[]> => {
    const attributes = getAttributesFromAuthenticatedUser(user);
    const accountId = attributes['custom:account_id'];
    const token = getTokenFromAuthenticatedUser(user);
    const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };

        const documentSignRequiredResult = await fetch(
            `${API_BASE_URL}/${ACCOUNT_ENDPOINT}/v1/accounts/${Number(
                accountId
            )}/document-sign-required`,
            requestOptions
        ).then((res) => res.json());

        return documentSignRequiredResult.data as Document[];
    } catch (error) {
        await captureSentryError(error, { feature: 'getDococumentsSignRequired' });
        throw new Error(`Failed to get account documents required to sign: ${error.message}`);
    }
};
