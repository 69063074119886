import { Account } from 'src/features/login/api/account.types';
import { CustomerData } from 'src/types/CustomerData';
import { Contract } from '../types/Contract';

export const evaluateClaimLimitReached = (contract: Contract): boolean => {
    const annualClaimCostGbp = (contract.annualClaimCostGbx || 0) / 100;
    if (
        contract.packageDetails.annualClaimCostLimitGbp &&
        annualClaimCostGbp &&
        annualClaimCostGbp > contract.packageDetails.annualClaimCostLimitGbp - 50
    ) {
        return true;
    }

    return false;
};

export const buildCustomerData = (
    account: Account | null,
    contracts: Contract[] | undefined | null
): CustomerData | null => {
    if (!account || !contracts || contracts.length === 0) {
        return null;
    }

    const contractData = contracts.map((contract) => {
        return {
            packageId: contract.packageId,
            isOilPackage: contract.isOilPackage,
            includedProducts: contract.includedProducts,
            billingType: contract.billingType,
            contribution: contract.contribution || 0,
            customerFacingId: contract.contractCustomerFacingId,
            contractId: contract.id,
            sk_account_id: contract.skAccountId,
            postcode: contract.property?.postcode,
            jobs: contract.jobs,
            claimLimitReached: evaluateClaimLimitReached(contract),
            packageDetails: {
                onlyLossOfService: contract.packageDetails?.onlyLossOfService,
                heatPumpService: contract.packageDetails?.heatPumpService,
                tapsService: contract.packageDetails?.tapsService,
                showersService: contract.packageDetails?.showersService,
                requiresInsurerApproval: contract.packageDetails?.requiresInsurerApproval,
            },
            appliances: contract.appliances,
            isRenewal: contract.isRenewal,
            startDate: contract.startDate,
        };
    });

    const combinedPackageDetails = contractData.reduce(
        (acc, contract) => {
            Object.entries(contract.packageDetails || {}).forEach(([key, value]) => {
                if (value) {
                    acc[key] = true;
                }
            });
            return acc;
        },
        {
            onlyLossOfService: false,
            heatPumpService: false,
            tapsService: false,
            showersService: false,
            requiresInsurerApproval: false,
        }
    );

    return {
        firstName: account.firstName,
        lastName: account.lastName,
        email: account.email,
        contracts: contractData,
        allIncludedProducts: contractData.map((contract) => contract.includedProducts).flat(),
        allPackageDetails: combinedPackageDetails,
    };
};
