import { appliancesTypes } from 'src/types/Appliances';

export const jobTypeMap = {
    boiler: 'Gas Job',
    heating: 'Gas Job',
    electric: 'Electric',
    plumbing: 'Plumbing',
    drainage: 'Drains',
    windows: 'Windows',
    locks: 'Locks',
    pests: 'Pests',
    doors: 'Doors',
    appliances: [
        'Dishwasher',
        'Coffee Machine',
        'Cooker',
        'Freezer',
        'Fridge',
        'Fridge Freezer',
        'Hob',
        'Microwave',
        'Oven',
        'Tumble Dryer',
        'Television',
        'Washer Dryer',
        'Washing Machine',
        'Wine Cooler',
        'Tumble Dryer Electric',
        'Hob Electric',
        'Oven Electric',
        'Cooker Electric',
        'Tumble Dryer Gas',
        'Hob Gas',
        'Oven Gas',
        'Cooker Gas',
        'Cooker Dual',
        'Satellite Box',
        'Vacuum Cleaner',
        'Cooker Hood',
    ],
    ...appliancesTypes,
};
