import Layout from 'src/components/Layout';

import { ArrowRight } from 'src/images';
import { Contract } from '../../api/getContract.types';
import styles from './index.module.scss';

interface PropertyListProps {
    properties: Contract['property'][];
    onPropertySelect: (propertyId: number) => void;
}

const PropertyList = ({ properties, onPropertySelect }: PropertyListProps) => {
    return (
        <Layout>
            <div className={`container ${styles.properties}`}>
                <p className={styles.subText}>
                    Please select the property you wish to request a repair for
                </p>
                <div className={styles.propertiesList}>
                    {properties.map((property, index) => (
                        <button
                            type="button"
                            key={property.id}
                            onClick={() => onPropertySelect(property.id)}
                            className={styles.contractContainer}
                            data-testid={`contract-select-address-button-${index}`}
                        >
                            <div>
                                {property.address.line1}, {property.town}, {property.postcode}
                            </div>
                            <ArrowRight />
                        </button>
                    ))}
                </div>
            </div>
        </Layout>
    );
};

export default PropertyList;
