import {
    CoffeeMachine,
    Cooker,
    CookerHood,
    Dishwasher,
    Freezer,
    Fridge,
    FridgeFreezer,
    Hob,
    Microwave,
    Other,
    Oven,
    SatBox,
    Television,
    TumbleDryer,
    VacuumCleaner,
    WasherDryer,
    WashingMachine,
    WineCooler,
} from '@HometreeEngineering/component-library';

export const mapIconsToAppliances = (appliance: string) => {
    switch (appliance) {
        case 'Fridge Freezer':
            return FridgeFreezer;
        case 'Coffee Machine':
            return CoffeeMachine;
        case 'Cooker':
        case 'Cooker Electric':
        case 'Cooker Gas':
        case 'Cooker Dual':
            return Cooker;
        case 'Cooker Hood':
            return CookerHood;
        case 'Dishwasher':
            return Dishwasher;
        case 'Freezer':
            return Freezer;
        case 'Fridge':
            return Fridge;
        case 'Hob':
        case 'Hob Electric':
        case 'Hob Gas':
            return Hob;
        case 'Microwave':
            return Microwave;
        case 'Oven':
        case 'Oven Electric':
        case 'Oven Gas':
            return Oven;
        case 'Television':
            return Television;
        case 'Tumble Dryer':
        case 'Tumble Dryer Electric':
        case 'Tumble Dryer Gas':
            return TumbleDryer;
        case 'Vacuum Cleaner':
            return VacuumCleaner;
        case 'Washer Dryer':
            return WasherDryer;
        case 'Washing Machine':
            return WashingMachine;
        case 'Wine Cooler':
            return WineCooler;
        case 'Satellite Box':
            return SatBox;
        default:
            return Other;
    }
};
