import { JOBS_PUBLIC_ENDPOINT } from 'src/constants';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';
import { getMandatoryEnvVar } from 'src/utils/env';
import { FormState } from '../types/Form';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export interface FnolTrackingRequestBody {
    id?: number;
    contractId: number;
    userMode: string;
    formData?: Partial<FormState>;
    stageHistory?: string[];
    lastStage?: string;

    skJobUid?: string;
}

export const trackFnolData = async (data: FnolTrackingRequestBody) => {
    try {
        const response = await fetch(
            `${API_BASE_URL}/${JOBS_PUBLIC_ENDPOINT}/v1/jobs/fnol-tracking`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }
        );

        const responseBody = (await response.json()) as { id: number };

        if (!response.ok) {
            await captureSentryError(new Error('Error calling FNOL tracking'), {
                ...responseBody,
                message: `Error occured during creation of claim`,
                contractId: data.contractId || '',
            });

            return new Error(`An error ocurred during the FNOL tracking request`);
        }

        return responseBody.id;
    } catch (err) {
        await captureSentryError(err, {
            message: `Error occured during creation of claim`,
            customerFacingId: data.contractId || '',
        });

        return new Error(`An error ocurred during the FNOL tracking request`);
    }
};
