import { useQueries } from 'react-query';
import { Account } from 'src/features/login/api/account.types';
import { getContractById } from '../features/newClaim/api/getContract';
import { Contract } from '../features/newClaim/types/Contract';

export const useGetContractsQuery = (accountData: Account | undefined | null) => {
    const contractIds = accountData?.contracts?.map((contract) => contract.id) || [];

    const results = useQueries(
        contractIds.map((contractId) => ({
            queryKey: ['contract', contractId],
            queryFn: () =>
                getContractById({
                    queryKey: ['contract', String(contractId)],
                    meta: undefined,
                }),
            enabled: !!contractId,
            staleTime: Infinity,
        }))
    );

    const isLoading = results.some((result) => result.isLoading);
    const isError = results.some((result) => result.isError);
    const errors = results.map((result) => result.error).filter(Boolean) as Error[];

    const data: Contract[] | null = results.every((result) => result.status === 'loading')
        ? null
        : results
              .map((result) => result.data)
              .filter((item): item is Contract => item !== null && item !== undefined);

    return {
        isLoading,
        isError,
        errors,
        data,
    };
};
