import { FormState } from '../types/Form';
import { QuestionStage, QuestionType } from '../types/Question';

export const pestsQuestions = (): QuestionStage[] => {
    return [
        {
            handle: 'pestsGroup1',
            questions: [
                {
                    name: 'pestsType',
                    description: 'What unwelcome guests have invaded your home?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Rats & Mice', value: 'Rats & Mice' },
                            { copy: 'Squirrels', value: 'Squirrels' },
                            {
                                copy: 'Crawling Pests/Non-flying insects',
                                value: 'Crawling Pests/Non-flying insects',
                            },
                            { copy: 'Fleas & Cluster Flies', value: 'Fleas & Cluster Flies' },
                            { copy: 'Wasps & Hornets', value: 'Wasps & Hornets' },
                            { copy: 'Bees', value: 'Bees' },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    pestsType: values.pestsType,
                } as Partial<FormState>;

                if (values.pestsType === 'Squirrels') {
                    formState.rejectProduct = 'Squirrels';
                }

                if (values.pestsType === 'Crawling Pests/Non-flying insects') {
                    formState.rejectProduct = 'Crawling Pests/Non-flying insects';
                }

                if (values.pestsType === 'Fleas & Cluster Flies') {
                    formState.rejectProduct = 'Fleas & Cluster Flies';
                }

                if (values.pestsType === 'Bees') {
                    formState.rejectProduct = 'Bees';
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (
                            [
                                'Squirrels',
                                'Crawling Pests/Non-flying insects',
                                'Fleas & Cluster Flies',
                                'Bees',
                            ].includes(values.pestsType as string)
                        ) {
                            return 'optionNotCovered';
                        }

                        if (values.pestsType === 'Rats & Mice') {
                            return 'miceGroup1';
                        }

                        if (values.pestsType === 'Wasps & Hornets') {
                            return 'waspGroup1';
                        }

                        return 'vulnerability';
                    },
                },
            ],
        },
        {
            handle: 'miceGroup1',
            questions: [
                {
                    name: 'pestsMiceInsideHome',
                    description: 'Is the pest problem inside your home?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    pestsMiceInsideHome: values.pestsMiceInsideHome,
                } as Partial<FormState>;

                if (values.pestsMiceInsideHome === 'No') {
                    formState.rejectProduct = 'pests that are not inside your home';
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (values.pestsMiceInsideHome === 'No') {
                            return 'optionNotCovered';
                        }

                        return 'vulnerability';
                    },
                },
            ],
        },
        {
            handle: 'waspGroup1',
            questions: [
                {
                    name: 'pestsWaspsInsideProperty',
                    description:
                        'Is the nest inside your property (includes the garden, outbuildings or garage)?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    pestsWaspsInsideProperty: values.pestsWaspsInsideProperty,
                } as Partial<FormState>;

                if (values.pestsWaspsInsideProperty === 'No') {
                    formState.rejectProduct = 'a nest located outside of your property';
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (values.pestsWaspsInsideProperty === 'No') {
                            return 'optionNotCovered';
                        }

                        return 'vulnerability';
                    },
                },
            ],
        },
    ];
};
