import { HAS_SERVICE_ANSWER } from 'src/utils/jobs';
import { FormState } from '../types/Form';
import { QuestionStage, QuestionType } from '../types/Question';

export const heatingQuestions = (_formState: Partial<FormState>): QuestionStage[] => {
    return [
        {
            handle: 'heatingGroup1',
            questions: [
                {
                    name: 'problemType',
                    description: 'Do you have any of the following?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            {
                                copy: 'Complete loss of hot water, heating, or both',
                                value: 'Complete loss of hot water, heating, or both',
                            },
                            {
                                copy: 'A serious water leak that requires me to turn off water supply',
                                value: 'A serious water leak that requires me to turn off water supply',
                            },
                            {
                                copy: HAS_SERVICE_ANSWER,
                                value: HAS_SERVICE_ANSWER,
                            },
                        ],
                    },
                },
                {
                    name: 'heatingProblemRelateTo',
                    description: 'Does your issue relate to:',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            {
                                copy: 'Radiators',
                                value: 'Radiators',
                            },
                            {
                                copy: 'Cylinder or immersion heater',
                                value: 'Cylinder or immersion heater',
                            },
                            {
                                copy: 'Heating pipes',
                                value: 'Heating pipes',
                            },
                            {
                                copy: 'Underfloor heating systems',
                                value: 'Underfloor heating systems',
                            },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    problemType: values.problemType,
                    heatingProblemRelateTo: values.heatingProblemRelateTo,
                } as Partial<FormState>;

                if (values.heatingProblemRelateTo === 'Underfloor heating systems') {
                    formState.rejectProduct = 'Underfloor heating systems';
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (values.heatingProblemRelateTo === 'Underfloor heating systems') {
                            return 'optionNotCovered';
                        }

                        if (
                            values.problemType ===
                            'A serious water leak that requires me to turn off water supply'
                        ) {
                            return 'heatingGroup2';
                        }

                        if (
                            values.problemType === HAS_SERVICE_ANSWER &&
                            _formState?.packageDetails?.requiresInsurerApproval === true &&
                            _formState?.packageDetails?.onlyLossOfService === true
                        ) {
                            return 'lossOfServiceNotCoveredNoExtraCopy';
                        }

                        if (values.heatingProblemRelateTo === 'Heating pipes') {
                            return 'heatingLeakVisibility';
                        }

                        if (
                            values.problemType === HAS_SERVICE_ANSWER &&
                            _formState?.packageDetails &&
                            _formState?.packageDetails.onlyLossOfService === true
                        ) {
                            return 'lossOfServiceNotCovered';
                        }

                        return 'vulnerability';
                    },
                },
            ],
        },
        {
            handle: 'heatingGroup2',
            questions: [
                {
                    name: 'heatingLeakVisibility',
                    description: 'Is the leaking pipework visible and accessible?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    heatingLeakVisibility: values.heatingLeakVisibility,
                } as Partial<FormState>;

                if (values.heatingLeakVisibility === 'No') {
                    formState.rejectProduct = 'leaks that are not visible';
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (
                            values.heatingLeakVisibility === 'No' &&
                            _formState?.packageDetails?.onlyLossOfService === true &&
                            _formState?.packageDetails?.requiresInsurerApproval === true
                        ) {
                            return 'optionNotCovered';
                        }

                        return 'vulnerability';
                    },
                },
            ],
        },
        {
            handle: 'heatingLeakVisibility',
            questions: [
                {
                    name: 'heatingLeakVisibility',
                    description: 'Is there a visible leak?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    heatingLeakVisibility: values.heatingLeakVisibility,
                } as Partial<FormState>;

                if (values.heatingLeakVisibility === 'No') {
                    formState.rejectProduct = 'leaks that are not visible';
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (
                            values.heatingLeakVisibility === 'No' &&
                            _formState?.packageDetails?.onlyLossOfService === true &&
                            _formState?.packageDetails?.requiresInsurerApproval === true
                        ) {
                            return 'optionNotCovered';
                        }

                        return 'vulnerability';
                    },
                },
            ],
        },
    ];
};
