import { useEffect, useState } from 'react';
import ErrorBar from 'src/components/ErrorBar';
import Loader from 'src/components/Loader';
import { HOMETREE_PHONE_NUMBER } from 'src/constants';
import { normaliseAddress } from 'src/features/login/api/account';
import { Account } from 'src/features/login/api/account.types';
import { Contract, Property } from 'src/features/newClaim/api/getContract.types';
import { useAccountQuery } from 'src/hooks/useAccountQuery';
import NewClaim from '../NewClaim';
import PropertyList from '../Properties';
import styles from './index.module.scss';

const BookingTool = () => {
    const {
        data: accountData,
        isLoading: isAccountLoading,
        isError: isAccountError,
        error: accountError,
    } = useAccountQuery();

    const [selectedPropertyId, setSelectedPropertyId] = useState<number | null>(null);
    const [errors, setErrors] = useState<string[]>([]);

    const getSelectedContracts = (contracts: Contract[]) => {
        const filteredContracts = contracts.filter(
            (contract) => contract.property.id === selectedPropertyId
        );

        const newAccountData = {
            ...accountData,
            contracts: filteredContracts,
        };

        return newAccountData as Account;
    };

    useEffect(() => {
        if (accountData?.contracts) {
            const uniqueProperties = accountData.contracts.reduce(
                (acc: Record<string, Contract['property']>, contract) => {
                    const normalizedAddressKey = normaliseAddress(contract.property);
                    if (!acc[normalizedAddressKey]) {
                        acc[normalizedAddressKey] = contract.property;
                    }
                    return acc;
                },
                {}
            );
            const propertiesArray = Object.values(uniqueProperties);

            if (propertiesArray.length === 1) {
                setSelectedPropertyId(propertiesArray[0].id);
            }
        }
    }, [accountData]);

    const handlePropertySelect = (propertyId: number) => {
        setSelectedPropertyId(propertyId);
    };

    const normaliseData = (data): Property[] =>
        Object.values(
            data.contracts.reduce((acc: Record<string, Contract['property']>, contract) => {
                const normalizedAddressKey = normaliseAddress(contract.property);
                if (!acc[normalizedAddressKey]) {
                    acc[normalizedAddressKey] = contract.property;
                }
                return acc;
            }, {})
        );

    if (isAccountLoading) {
        return <Loader />;
    }

    if (isAccountError) {
        setErrors((prevErrors) => [
            ...prevErrors,
            accountError?.message || 'Failed to load account data',
        ]);
    }

    if (!accountData?.contracts || accountData.contracts.length === 0) {
        return (
            <ErrorBar
                message={`No contracts found for account. Please give us a call on ${HOMETREE_PHONE_NUMBER} and we will be able to help you.`}
                style={styles.claimFormErrorBar}
            />
        );
    }

    const newClaimDisplay = accountData.contracts?.length > 0 && (
        <NewClaim accountData={getSelectedContracts(accountData.contracts)} />
    );

    return (
        <>
            {errors.length > 0 && (
                <ErrorBar message={`We encountered some issues: ${errors.join(', ')}`} />
            )}
            {!selectedPropertyId &&
            accountData?.contracts &&
            normaliseData(accountData).length > 1 ? (
                <PropertyList
                    properties={normaliseData(accountData)}
                    onPropertySelect={handlePropertySelect}
                />
            ) : (
                newClaimDisplay
            )}
        </>
    );
};

export default BookingTool;
