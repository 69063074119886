import { useEffect } from 'react';
import { HOMETREE_PHONE_NUMBER } from 'src/constants';
import { Account } from 'src/features/login/api/account.types';
import { Contract } from 'src/features/newClaim/types/Contract';
import { FormState } from 'src/features/newClaim/types/Form';
import { CustomerData } from 'src/types/CustomerData';
import { isContractWithinCoolingOffPeriod } from 'src/utils/isContractWithinCoolingOffPeriod';

export const useHandleNewClaimErrors = (
    formState: Partial<FormState>,
    customerData: CustomerData | null | undefined,
    allContractData: Contract[] | null | undefined,
    setNewClaimError: (error: string) => void,
    claimCreateError: Error | null,
    isError: boolean,
    accountData: Account,
    isContractLoading: boolean,
    contractErrors: Error[]
) => {
    useEffect(() => {
        const selectedContract = customerData?.contracts?.find(
            (contract) => contract.contractId === formState.contractId
        );

        if (formState.startDate) {
            const isWithinCoolingOff = isContractWithinCoolingOffPeriod(
                new Date(formState.startDate),
                formState.isRenewal
            );
            if (isWithinCoolingOff) {
                setNewClaimError(
                    `You are in your cooling-off period, so we will not be able to process your claim at this time. Please try again after 14 days have passed since your start date.`
                );
            }
        }

        if (selectedContract?.claimLimitReached) {
            setNewClaimError(
                `Unfortunately, you are above or at risk of being above your annual claim limit mandated by your T&Cs. Please reach out to us by calling ${HOMETREE_PHONE_NUMBER} between 8:00-19:00 so we can support you on your next steps with raising your claim`
            );
        }

        const contractForPayments = allContractData?.find(
            (contract) => contract.id === formState.contractId
        );

        if (contractForPayments?.canRaiseClaim === null && !contractForPayments?.paymentsUpToDate) {
            setNewClaimError(
                `It seems like your payments are not up to date, so we will not be able to process your claim. You will need to give us a call on ${HOMETREE_PHONE_NUMBER} and reset your payments before we can book your claim.`
            );
        }

        if (contractForPayments?.canRaiseClaim === false) {
            setNewClaimError(
                `You cannot currently raise a claim. You will need to give us a call on ${HOMETREE_PHONE_NUMBER} before we can book your claim.`
            );
        }
    }, [
        formState.contractId,
        customerData?.contracts,
        allContractData,
        setNewClaimError,
        formState.startDate,
        formState.isRenewal,
    ]);

    useEffect(() => {
        if (claimCreateError && isError) {
            setNewClaimError(
                `It seems like something went wrong. Please refresh and try again. If this message persists please call ${HOMETREE_PHONE_NUMBER}`
            );
        }
    }, [isError, claimCreateError, setNewClaimError]);

    useEffect(() => {
        if (
            accountData?.contracts?.length > 0 &&
            !isContractLoading &&
            allContractData?.length === 0 &&
            contractErrors.length > 0 &&
            accountData.contracts.length === contractErrors.length
        ) {
            setNewClaimError(
                `We couldn't retrieve contract information: ${contractErrors
                    .map((e) => e.message)
                    .join(', ')}`
            );
        }
    }, [
        isContractLoading,
        allContractData,
        contractErrors,
        accountData?.contracts?.length,
        setNewClaimError,
    ]);
};
