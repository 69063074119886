export const getAppliance = (formstate) => {
    const appliance = formstate?.appliances?.find((app) => {
        return app.id === formstate?.applianceId;
    });

    const purchaseMonth = appliance?.purchaseDate
        ? new Date(appliance?.purchaseDate).toLocaleString('default', { month: 'long' })
        : null;

    const purchaseYear = appliance?.purchaseDate
        ? new Date(appliance.purchaseDate).getFullYear().toString()
        : null;

    return { ...appliance, purchaseMonth, purchaseYear };
};
