import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from 'src/components/Layout';
import BubbleHeading from 'src/features/login/components/bubbleHeading';

import { Modal } from '@HometreeEngineering/component-library';
import ErrorBar from 'src/components/ErrorBar';
import { useAppContext } from 'src/contexts/AppContext';
import { getMandatoryEnvVar } from 'src/utils/env';
import { isPartnerPackage, isSmartCoverPackage } from 'src/utils/isPartnerPackage';

import FormValidator, {
    formatPostcode,
    handleEmailValidation,
    handlePostcodeValidation,
} from './validation';

import { sendAuthRequest } from '../../api/authentication';
import { LoginError } from '../../types/LoginErrors';
import { Routes } from '../../types/Routes';
import { newAnalyticsEvent, newVirtualPageView } from '../../utils/analytics';
import { getUserModeId } from '../../utils/userMode';
import Button from '../Button';
import styles from './index.module.scss';

export enum CustomerType {
    SMART_COVER = 'Smart Cover',
    OPENRENT = 'OpenRent',
}

const AuthForm = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [email, setEmail] = useState('');
    const [postcode, setPostcode] = useState('');
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [customerType, setCustomerType] = useState<CustomerType>();
    const {
        state: { paramUserMode, paramClaimMode },
        dispatch,
    } = useAppContext();
    const navigate = useNavigate();

    const smartCoverPackageLink = getMandatoryEnvVar('REACT_APP_SMART_COVER_PACKAGE_LINK');
    const openRentPackageLink = getMandatoryEnvVar('REACT_APP_OPENRENT_PACKAGE_LINK');

    useEffect(() => {
        newVirtualPageView('Authentication', '/vpv/authentication');
        newAnalyticsEvent({
            category: 'Authentication',
            action: 'Landed',
            value: getUserModeId(paramUserMode),
        });
    }, [paramUserMode]);

    const registerNotAuthenticated = () => {
        newAnalyticsEvent({
            category: 'Authentication',
            action: 'Not authenticated',
            label: postcode,
            value: getUserModeId(paramUserMode),
        });
    };

    const redirectToClaimPage = () => {
        const claimRoute =
            paramClaimMode === 'new' ? Routes.CLAIM_NEW_IN_PROGRESS : Routes.CLAIM_NEW;

        navigate(claimRoute, {
            state: { email, postcode },
        });
    };

    const sendAuth = async () => {
        newAnalyticsEvent({
            category: 'Authentication',
            action: 'Clicked find me',
            label: postcode,
            value: getUserModeId(paramUserMode),
        });

        // Revalidate inputs before submitting
        const emailValid = FormValidator.validateEmail(email);
        const postcodeValid = FormValidator.validatePostcode(postcode);

        if (!(emailValid && postcodeValid) || email === '' || postcode === '') {
            return;
        }

        setError('');
        setIsSubmitting(true);

        try {
            // TODO fix with CustomerData type once implemented
            const customerData = (await sendAuthRequest({ email, postcode })) as any;

            dispatch({ type: 'setState', payload: { ...customerData, email, postcode } });

            if (isPartnerPackage(customerData.packageId) && paramUserMode?.toLowerCase() === 'cs') {
                const isSmartCoverCustomer = isSmartCoverPackage(customerData.packageId);
                setCustomerType(
                    isSmartCoverCustomer ? CustomerType.SMART_COVER : CustomerType.OPENRENT
                );
                setIsModalOpen(true);
            } else {
                redirectToClaimPage();
            }
            setIsSubmitting(false);
        } catch (err) {
            registerNotAuthenticated();
            // eslint-disable-next-line no-console
            console.error(err);
            setError(err.message);
            setIsSubmitting(false);
        }
    };

    return (
        <Layout>
            <form className={styles.loginSection}>
                <BubbleHeading heading={`Let's find you!`} subheading="Just pop in your details" />
                <div className={styles.form}>
                    <input
                        name="email"
                        type="email"
                        className=""
                        value={email}
                        placeholder="Email address"
                        onChange={(e) => {
                            if (error === LoginError.INVALID_EMAIL) {
                                setError('');
                            }
                            setEmail(e.target.value);
                        }}
                        onBlur={() => handleEmailValidation(email, error, setError)}
                    />
                    <input
                        name="postcode"
                        type="text"
                        className=""
                        value={postcode}
                        placeholder="Postcode"
                        onChange={(e) => {
                            if (error === LoginError.INVALID_POSTCODE) {
                                setError('');
                            }
                            setPostcode(e.target.value);
                        }}
                        onBlur={() => {
                            const formattedPostcode = formatPostcode(postcode);
                            setPostcode(formattedPostcode);
                            handlePostcodeValidation(formattedPostcode, error, setError);
                        }}
                    />
                </div>
                {error && <ErrorBar message={error} style={styles.authFormErrorBar} />}

                <div className={styles.buttonContainer}>
                    <Button
                        id="auth_submit"
                        type="button"
                        isDisabled={isSubmitting}
                        onClick={sendAuth}
                    >
                        {isSubmitting ? 'Finding...' : 'Find me'}
                    </Button>

                    <a href="https://www.hometree.co.uk/products/boiler-cover-insurance/">
                        Not a customer yet?
                    </a>
                </div>
            </form>
            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    handleOnClick={redirectToClaimPage}
                    style={{ background: '#fff', width: '95%' }}
                    buttonProps={{
                        btnText: 'Continue',
                        btnClass: styles.confirmBtn,
                        btnVariant: 'primary',
                    }}
                    handleOnClose={() => setIsModalOpen(false)}
                >
                    <div className={styles.modalContent}>
                        <h2 className={styles.modalTitle}>
                            Please note this is{' '}
                            {customerType === CustomerType.SMART_COVER
                                ? `a ${CustomerType.SMART_COVER}`
                                : `an ${CustomerType.OPENRENT}`}{' '}
                            customer
                        </h2>
                        <div className={styles.description}>
                            <p>
                                Some restrictions and changes to normal process will apply to this
                                customer&apos;s account. <br />
                                <span className={styles.packageLink}>
                                    Please review the{' '}
                                    <a
                                        href={
                                            customerType === CustomerType.SMART_COVER
                                                ? smartCoverPackageLink
                                                : openRentPackageLink
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Notion Page
                                    </a>{' '}
                                    if needed.
                                </span>
                            </p>
                        </div>
                    </div>
                </Modal>
            )}
        </Layout>
    );
};

export default AuthForm;
