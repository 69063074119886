import { Modal } from '@HometreeEngineering/component-library';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from 'src/types/Routes';
import { Document } from '../termsAndConditions/api/getDocumentsSignRequired';
import style from './index.module.scss';

interface Props {
    documents: Document[];
}

const DocumentsModal = ({ documents }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    const handleClickEvent = () => {
        localStorage.setItem('redirectAfterSign', location.pathname);
        setIsModalOpen(false);
        navigate(Routes.TERMS_AND_CONDITIONS);
    };

    const formatDocumentsDisplayName = (docs: Document[]) => {
        return docs.map((doc) => doc.displayName).join(', ');
    };

    return (
        <>
            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    handleOnClick={handleClickEvent}
                    style={{ background: '#fff', width: '95%' }}
                    buttonProps={{
                        btnText: 'Next',
                        btnVariant: 'primary',
                    }}
                    customClass={`${style.modal}`}
                    hideCloseButton
                >
                    <div>
                        <h2>Before you continue...</h2>
                        <p>
                            You have {documents.length} documents that requires to be signed:{' '}
                            {formatDocumentsDisplayName(documents)}
                        </p>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default DocumentsModal;
