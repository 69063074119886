import { Appliance, AppliancePayload } from 'src/types/Appliances';
import { FormState } from '../types/Form';

export const determineApplianceDataToUpdate = (
    formState: Partial<FormState>,
    applianceToUpdate: Appliance,
    appliances: Appliance[] | undefined
): AppliancePayload => {
    const dataToUpdate = {
        id: formState.applianceId,
    } as AppliancePayload;
    if (formState.applianceMaker !== applianceToUpdate.make && formState.applianceMakeId) {
        dataToUpdate.makeId = formState.applianceMakeId;
    }
    if (formState.applianceModel !== applianceToUpdate.modelNumber && formState.applianceModel) {
        dataToUpdate.modelNumber = formState.applianceModel;
    }
    if (
        formState.applianceSerialNumber !== applianceToUpdate.serialNumber &&
        formState.applianceSerialNumber
    ) {
        dataToUpdate.serialNumber = formState.applianceSerialNumber;
    }
    if (
        formState.appliancePurchaseDate !== applianceToUpdate.purchaseDate &&
        formState.appliancePurchaseDate
    ) {
        dataToUpdate.purchaseDate = formState.appliancePurchaseDate;
    }
    // Only update appliance type if it has changed (only allowed for Hob, Oven, Cooker and Tumble Dryer)
    const hasJobTypeChanged = !appliances?.find((app) => app.type === formState.jobType);
    if (hasJobTypeChanged && formState.jobType) {
        dataToUpdate.type = formState.jobType;
    }
    return dataToUpdate;
};
