import { differenceInCalendarMonths, differenceInDays } from 'date-fns';

export const determineContributionAmount = ({
    isRenewal,
    contractStartDate,
    applianceAge = 0,
}: {
    isRenewal?: boolean | null;
    contractStartDate?: string;
    applianceAge?: number;
}): number => {
    if (contractStartDate) {
        const contractAge = differenceInDays(new Date(), new Date(contractStartDate));

        if (contractAge <= 45 && !isRenewal) return 25000;
        if (contractAge > 45 && applianceAge >= 12 && applianceAge < 84) return 0; // appliance age is in months
        if (contractAge > 45 && applianceAge >= 84) return 4500;
    }

    return 0;
};

export const determineApplianceAge = (purchaseDate): number => {
    const appllianceAge = differenceInCalendarMonths(new Date(), new Date(purchaseDate));

    return appllianceAge;
};
