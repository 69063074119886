import { useMemo, useState } from 'react';

import Loader from 'src/components/Loader';
import { CustomerData } from 'src/types/CustomerData';
import { getUserModeId } from 'src/utils/userMode';
import { FnolTrackingRequestBody } from '../../api/fnolTracking';
import { FormState } from '../../types/Form';
import { QuestionStage, QuestionStageValues } from '../../types/Question';
import { historicalJobsFilter } from '../../utils/historicalJobsFilter';
import CurrentStage from '../CurrentStage';
import { JobTable } from '../JobTable';
import styles from './index.module.scss';

interface Props {
    questionStages: QuestionStage[];
    initialStageHandle: string;
    endStageHandles: string[];
    formSubmit: (formState: Partial<FormState>) => void;
    isFormSubmitting: boolean;
    customerData: CustomerData;
    userMode: string;
    trackFnolData: (
        data: FnolTrackingRequestBody,
        setFnolTrackingId?: React.Dispatch<React.SetStateAction<number | null>>,
        fnolTrackingId?: number | null
    ) => Promise<void>;
    formState: Partial<FormState>;
    setFormState: (formState: Partial<FormState>) => void;
}

const Form = ({
    questionStages,
    initialStageHandle,
    formSubmit,
    isFormSubmitting,
    endStageHandles,
    customerData,
    userMode,
    trackFnolData,
    formState,
    setFormState,
}: Props) => {
    const [currentStageHandle, setCurrentStageHandle] = useState<string>(initialStageHandle);
    const [stageHistory, setStageHistory] = useState<string[]>([initialStageHandle]);

    const allJobsData = useMemo(
        () => customerData.contracts?.map((contract) => contract.jobs).flat(),
        [customerData.contracts]
    );

    const currentStage = questionStages.find((stage) => stage.handle === currentStageHandle);

    if (!currentStage) {
        // TODO add better error handling
        throw new Error(`No current stage found, trying to find: ${currentStageHandle}`);
    }

    const onStageSubmit = async (newStageState: QuestionStageValues, nextStageHandle: string) => {
        const currentStageCalculatedValues = currentStage.onSubmit(newStageState, formState);

        const updatedFormState = {
            ...formState,
            ...currentStageCalculatedValues,
        };

        if (stageHistory.includes(nextStageHandle)) {
            setStageHistory([nextStageHandle]);
        } else {
            const updatedStageHJistory = [...stageHistory, nextStageHandle];

            setStageHistory(updatedStageHJistory);

            setFormState(updatedFormState);

            trackFnolData({
                contractId: updatedFormState.contractId || customerData.contractId,
                formData: updatedFormState,
                stageHistory: updatedStageHJistory,
                lastStage: nextStageHandle,
                userMode: getUserModeId(userMode),
            });
        }

        // Submit form if we are on any of the last stage
        if (endStageHandles.includes(nextStageHandle)) {
            await formSubmit(updatedFormState);
        }

        setCurrentStageHandle(nextStageHandle);
    };

    if (isFormSubmitting) {
        return <Loader />;
    }

    return (
        <>
            {allJobsData?.length &&
            initialStageHandle === currentStageHandle &&
            allJobsData.filter(historicalJobsFilter).length > 0 &&
            userMode === 'customer' ? (
                <JobTable jobs={allJobsData.filter(historicalJobsFilter)} />
            ) : null}

            <div
                className={`claimform ${
                    ['appliancesGroup1', 'appliancesGroup2'].includes(currentStageHandle)
                        ? styles.fullWidth
                        : ''
                }`}
            >
                {stageHistory.map((eachStateHistory) => {
                    const questionStage = questionStages.find(
                        (stage) => stage.handle === eachStateHistory
                    );

                    if (typeof questionStage === 'undefined') {
                        throw new Error(
                            `No current stage found, trying to find: ${eachStateHistory}`
                        );
                    }

                    const isStageHidden =
                        eachStateHistory !== currentStageHandle ? styles.hiddenStage : '';

                    return (
                        <div className={isStageHidden} key={eachStateHistory}>
                            <CurrentStage
                                currentStage={questionStage}
                                formState={formState}
                                onStageSubmit={onStageSubmit}
                                contractId={formState?.contractId}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default Form;
