import { useNavigate } from 'react-router-dom';
import Layout from 'src/components/Layout';
import { Routes } from 'src/types/Routes';

import { useEffect } from 'react';
import ErrorBar from 'src/components/ErrorBar';
import Loader from 'src/components/Loader';
import { HOMETREE_PHONE_NUMBER } from 'src/constants';
import { Contract, Property } from 'src/features/newClaim/api/getContract.types';
import { useAccountQuery } from 'src/hooks/useAccountQuery';
import { isContractWithinCoolingOffPeriod } from 'src/utils/isContractWithinCoolingOffPeriod';
import ContractsList from './ContractsList';
import styles from './index.module.scss';

const ERROR_NO_CONTRACTS = `No contracts found for account. Please give us a call on ${HOMETREE_PHONE_NUMBER} and we will be able to help you.`;

const getUniqueProperties = (contracts: Contract[]): Property[] => {
    const seenAddresses = new Set<string>();

    return contracts.reduce<Property[]>((uniqueProperties, contract) => {
        const addressKey = JSON.stringify(contract.property);
        if (!seenAddresses.has(addressKey)) {
            seenAddresses.add(addressKey);
            uniqueProperties.push(contract.property);
        }
        return uniqueProperties;
    }, []);
};

export const Properties = () => {
    const { isLoading, isError, error, data } = useAccountQuery();
    const navigate = useNavigate();
    useEffect(() => {
        // When only one contract,
        // And that contract is not within the cool off period,
        // Go straight to claim form
        if (
            data?.contracts?.length === 1 &&
            !isContractWithinCoolingOffPeriod(
                new Date(data.contracts[0].startDate),
                data.contracts[0].isRenewal
            )
        ) {
            const contract = data.contracts[0];
            const newClaimRoute = Routes.CLAIM_NEW;

            navigate(newClaimRoute, {
                state: {
                    contractId: contract.id,
                },
            });
        }
    }, [data, navigate]);

    const navigateToNewClaim = (contractId: number) =>
        navigate(Routes.CLAIM_NEW, {
            state: { contractId },
        });

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <ErrorBar message={(error as Error).message} style={styles.propertiesErrorBar} />;
    }

    if (!data || !data.contracts || data.contracts.length === 0) {
        return <ErrorBar message={ERROR_NO_CONTRACTS} style={styles.propertiesErrorBar} />;
    }

    const uniqueProperties = getUniqueProperties(data.contracts);

    return (
        <>
            <Layout>
                <div className={`container ${styles.properties}`}>
                    <p className={styles.subText}>
                        Please select the property you wish to request a repair for
                    </p>
                    <ContractsList properties={uniqueProperties} onSelect={navigateToNewClaim} />
                </div>
            </Layout>
        </>
    );
};
