import { Appliance } from 'src/types/Appliances';
import { PackageDetails } from 'src/types/CustomerData';
import { AreaOfIssue, JobType } from 'src/types/Job';
import { JobUrgency } from 'src/types/JobUrgency';
import { ProductEnum } from 'src/types/ProductEnum';
import { SkeduloJob } from 'src/types/Skedulo';

export interface FormState {
    contribution: number;
    vulnerable: string;
    boilerMaker: string;
    boilerModel: string;
    boilerFaultCode: string;
    problemType: string;
    reattend: string;
    boilerAge: string;
    parking: string;
    firstName: string;
    lastName: string;
    billingFullName: string | null;
    customerFacingId: string;
    jobType: JobType | AreaOfIssue | string;
    dayOneDate: string;
    dayOneTime: string[];
    dayTwoDate: string;
    dayTwoTime: string[];
    dayThreeDate: string;
    dayThreeTime: string[];
    email: string;
    postcode: string;
    description?: string;
    paymentId: string | null;
    contractId: number | null;
    userMode: string;
    visitType: string | null;
    relatedJob: string | null;
    stripe_amount: number | null;
    sk_account_id: string;
    urgency: JobUrgency;
    gasLeak: string | null;
    gasHeat: string | null;
    gasMains: string | null;
    boilerIssue: string | null;
    electricalFailure?: string | null;
    electricIssue?: string | null;
    electricOutdoorAbove3m?: string | null;
    rejectProduct?: string;
    plumbingIssue?: string | null;
    heatingProblemRelateTo?: string | null;
    heatingLeakVisibility?: string | null;
    plumbingToiletType?: string | null;
    plumbingToiletLeak?: string | null;
    plumbingToiletFlushing?: string | null;
    plumbingToiletBlockage?: string | null;
    drainsProblemRelateTo?: string | null;
    affectedWindowInMainHouse?: string | null;
    externalWindow?: string | null;
    insecureProperty?: string | null;
    affectedLockInMainHouse?: string | null;
    lockedOutFromProperty?: string | null;
    lockOnExternalWindowOrDoor?: string | null;
    doorsIssueArea?: string | null;
    doorsTypeOf?: string | null;
    doorsElectric?: string | null;
    doorsSecureBuilding?: string | null;
    pestsType?: string | null;
    pestsMiceInsideHome?: string | null;
    pestsWaspsInsideProperty?: string | null;
    applianceMaker?: string | null;
    applianceModel?: string | null;
    applianceFaultCode?: string | null;
    applianceSerialNumber?: string | null;
    applianceAge?: number;
    applianceId?: number | null;
    appliancePurchaseDate?: string | null;
    lastBoilerService?: string | null;
    lastBoilerServiceYear?: { id: number; name: string } | null;
    lastBoilerServiceMonth?: { id: string; name: string } | null;
    applianceUrgencyIssue?: string | null;
    appliances?: Appliance[];
    jobs?: SkeduloJob[];
    includedProducts?: ProductEnum[];
    startDate?: string;
    isRenewal?: boolean;
    packageDetails: PackageDetails;
    applianceMakeId?: number | null;
}

export enum Reattend {
    YES = 'Yes',
    NO = 'No',
}
