import React, { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { PackageDetails } from 'src/types/CustomerData';
import { SkeduloJob } from '../types/Skedulo';
import { getClaimModeFromUrl, getUserModeFromUrl } from '../utils/userMode';

export type AppState = {
    showBillingDetailsForm?: boolean;
    paymentDetailsValid?: boolean;
    stripeStatus?: object;
    paramUserMode: string;
    paramClaimMode: string;
    customerFacingId?: string;
    firstName?: string;
    lastName?: string;
    postcode?: string;
    contractId?: number;
    contribution?: number;
    stripeError?: string;
    packageId?: number;
    includedProducts?: string[];
    jobs?: SkeduloJob[];
    vulnerable?: string;
    service?: string;
    urgency?: string;
    newClaimError?: string;
    documentsSignRequired?: boolean;
    justSignedDocsOn?: string;
    skeduloJobDetails?: {
        jobs: {
            edges: {
                node: {
                    Name: string;
                };
            }[];
        };
    };
    packageDetails?: PackageDetails;
    dayOneDate?: string;
    dayOneTime?: string[];
    dayTwoDate?: string;
    dayTwoTime?: string[];
    dayThreeDate?: string;
    dayThreeTime?: string[];
    jobUrgency?: string;
};

type AppAction = {
    type: 'setState';
    payload: Partial<AppState>;
};
type AppDispatch = (action: AppAction) => void;

const defaultState: AppState = {
    showBillingDetailsForm: true,
    paymentDetailsValid: false,
    stripeStatus: {},
    jobs: [],
    paramUserMode: getUserModeFromUrl(window.location.search),
    paramClaimMode: getClaimModeFromUrl(window.location.search),
};

const AppContext = createContext<
    | {
          state: AppState;
          dispatch: AppDispatch;
      }
    | undefined
>(undefined);

const appReducer = (state: AppState, action: AppAction): AppState => {
    switch (action.type) {
        case 'setState':
            return { ...state, ...action.payload };
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
};

export const AppProvider = ({
    children,
    initialState = defaultState,
}: {
    children: React.ReactNode;
    initialState?: AppState;
}) => {
    const [state, dispatch] = useReducer(appReducer, initialState as AppState);

    const memoizedDispatch = useCallback((action: AppAction) => {
        dispatch(action);
    }, []);

    const contextValue = useMemo(
        () => ({ state, dispatch: memoizedDispatch }),
        [state, memoizedDispatch]
    );

    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
