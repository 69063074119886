import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from 'src/components/Layout';
import { HOMETREE_PHONE_NUMBER } from 'src/constants';

import { useAppContext } from 'src/contexts/AppContext';
import { AreaOfIssue } from 'src/types/Job';
import { getMandatoryEnvVar } from 'src/utils/env';
import ErrorBar from '../../../../components/ErrorBar';
import Loader from '../../../../components/Loader';
import { EMAIL_AND_POSTCODE_REQUIRED, useCustomerQuery } from '../../../../hooks/useCustomerQuery';
import { Routes } from '../../../../types/Routes';
import { newAnalyticsEvent, newVirtualPageView } from '../../../../utils/analytics';
import { getUserModeId } from '../../../../utils/userMode';
import { createClaim } from '../../api/createClaim';
import { questionStages } from '../../config/questions';
import { jobTypeMap } from '../../data/jobTypeMap';
import { END_STAGE_HANDLES, INITIAL_STAGE_HANDLE } from '../../data/stageConfig';
import { FormState, Reattend } from '../../types/Form';
import { getMostRecentReattendJobName } from '../../utils/jobs';
import { trackFnolDataWrapper } from '../../utils/trackFnolDataWrapper';
import Form from '../Form';
import styles from './index.module.scss';

const NewClaimOldAuth = () => {
    const {
        state: { paramUserMode },
        dispatch,
    } = useAppContext();

    const location = useLocation();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formState, setFormState] = useState<Partial<FormState>>({});
    const [newClaimError, setNewClaimError] = useState('');
    const [fnolTrackingId, setFnolTrackingId] = useState<number | null>(null);
    const [reattend, setReattend] = useState<Reattend>(Reattend.NO);
    const [relatedJob, setRelatedJob] = useState<string | null>(null);

    const { email, postcode: locationPostcode } = (location.state as any) || {};
    const {
        data: customerQueryData,
        isLoading,
        isError,
        error: customerQueryError,
    } = useCustomerQuery(email, locationPostcode);
    const enabledAppliances = getMandatoryEnvVar('REACT_APP_APPLIANCES_ENABLED');
    useEffect(() => {
        // Wait until data is retrieved for customer
        if (customerQueryData?.customerFacingId) {
            newVirtualPageView('FNOL', '/vpv/fnol');
            newAnalyticsEvent({
                category: 'FNOL',
                action: 'Landed',
                label: customerQueryData.customerFacingId,
                value: getUserModeId(paramUserMode),
            });
        }
    }, [paramUserMode, customerQueryData, location]);

    useEffect(() => {
        // Wait until data is retrieved for customer
        if (customerQueryData?.jobs) {
            const appliancesTypes =
                formState.jobType === AreaOfIssue.APPLIANCES && jobTypeMap[formState.jobType];
            const jobTypeMapped = jobTypeMap[formState.jobType || ''];

            const mostRecentReattendJobName = getMostRecentReattendJobName(
                customerQueryData?.jobs || [],
                jobTypeMapped,
                appliancesTypes
            );

            setRelatedJob(mostRecentReattendJobName);
            setReattend(mostRecentReattendJobName ? Reattend.YES : Reattend.NO);
        }
    }, [formState, customerQueryData]);

    if (isLoading) {
        return <Loader />;
    }

    if (isError || !customerQueryData) {
        // if error is missing email or postcode, something has gone wrong within the flow
        // of the app and we should redirect to properties page
        const errorMessage = customerQueryError
            ? (customerQueryError as Error).message
            : 'Something went wrong';

        if (errorMessage === EMAIL_AND_POSTCODE_REQUIRED) {
            // Landed on this page skipping a step in the flow. We should look to tighten this up more
            // but for now should be enough to navigate back to start of the flow (properties route)
            // eslint-disable-next-line no-console
            console.error(errorMessage);
            navigate(Routes.PROPERTIES);
        }

        return <ErrorBar message={errorMessage} style={styles.claimFormErrorBar} />;
    }

    if (newClaimError) {
        const errorMessage = `It seems like something went wrong. Please refresh and try again. If this message persists please call ${HOMETREE_PHONE_NUMBER}`;

        return <ErrorBar message={errorMessage} style={styles.claimFormErrorBar} />;
    }

    const onSubmit = async (submitFormState: Partial<FormState>) => {
        setIsSubmitting(true);

        const jobTypeMapped = jobTypeMap[submitFormState.jobType || ''];

        const body = {
            ...submitFormState,
            ...customerQueryData,
            email,
            postcode: locationPostcode,
            reattend,
            relatedJob,
            userMode: paramUserMode?.toLowerCase(),
            parking: 'Yes',
            jobType: jobTypeMapped,
            ...(reattend === Reattend.YES && { visitType: 'Reattend' }),
        };

        newAnalyticsEvent({
            category: 'FNOL',
            action: 'Clicked create job',
            label: customerQueryData.customerFacingId,
            value: getUserModeId(paramUserMode),
        });

        try {
            const createClaimResult = await createClaim(body);
            dispatch({
                type: 'setState',
                payload: {
                    ...body,

                    // TODO: This is how confirmation expects the data, simplify once we get rid of the old newClaim page
                    skeduloJobDetails: {
                        jobs: {
                            edges: [
                                {
                                    node: {
                                        Name: createClaimResult.jobName,
                                    },
                                },
                            ],
                        },
                    },
                },
            });

            if (customerQueryData.contractId) {
                const dataToTrack = {
                    contractId: customerQueryData?.contractId,
                    skJobUid: createClaimResult.jobUid,
                    userMode: getUserModeId(paramUserMode),
                };
                await trackFnolDataWrapper(dataToTrack, setFnolTrackingId, fnolTrackingId);
            }
            navigate(Routes.CLAIM_CONFIRMATION);
        } catch (e) {
            setNewClaimError('Error creating claim');
        }

        // Navigate to confirmation page

        setIsSubmitting(false);
    };

    return (
        <Layout>
            <Form
                questionStages={questionStages(customerQueryData, formState, reattend)}
                initialStageHandle={
                    enabledAppliances === 'true' ? 'problemAreaNew' : INITIAL_STAGE_HANDLE
                }
                endStageHandles={END_STAGE_HANDLES}
                formSubmit={onSubmit}
                isFormSubmitting={isSubmitting}
                customerData={customerQueryData}
                userMode={paramUserMode}
                trackFnolData={(data) =>
                    trackFnolDataWrapper(data, setFnolTrackingId, fnolTrackingId)
                }
                formState={formState}
                setFormState={setFormState}
            />
        </Layout>
    );
};

export default NewClaimOldAuth;
