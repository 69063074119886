import { ACCOUNT_ENDPOINT } from 'src/constants';
import { getMandatoryEnvVar } from 'src/utils/env';
import {
    getAuthenticatedUser,
    getTokenFromAuthenticatedUser,
} from '../../login/utils/authenticatedUser';
import { EmbeddedLinkRequest } from '../types/EmbeddedLinkRequest';
import { Document } from './getDocumentsSignRequired';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const postEmbeddedLink = async (data: EmbeddedLinkRequest, documents: Document[]) => {
    const user = await getAuthenticatedUser();
    const token = getTokenFromAuthenticatedUser(user);

    try {
        const response = await fetch(
            `${API_BASE_URL}/${ACCOUNT_ENDPOINT}/v1/engineer/terms-conditions`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    ...data,
                    templateIds: documents.map((doc) => doc.templateId),
                }),
            }
        );

        // TODO type this response
        const result = await response.json();

        return result;
    } catch (err) {
        throw new Error(`Failed to create embedded link - ${err.message}`);
    }
};
