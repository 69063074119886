import { format, sub } from 'date-fns';
import { useQuery } from 'react-query';
import { getAttributesFromAuthenticatedUser, getAuthenticatedUser } from 'src/features/login';
import { JobDetails } from 'src/types/Job';
import { Role } from 'src/types/Role';
import { getJobAgenda } from '../../jobAgenda/api/getJobAgenda';
import { Document, getDocumentsSignRequired } from '../api/getDocumentsSignRequired';

export const determineIfAnyJobIsInProgress = (jobs: JobDetails[]) => {
    return (
        jobs.filter((job) => ['in progress', 'en route'].includes(job.sk_job_status.toLowerCase()))
            .length > 0
    );
};
export const useGetDocumentsSignRequired = (queryKey: string) =>
    useQuery<Document[], Error>([queryKey], async () => {
        const user = await getAuthenticatedUser();
        const attributes = getAttributesFromAuthenticatedUser(user);
        const role = attributes['custom:role'];

        if (role !== Role.ENGINEER) return [];

        const documents = await getDocumentsSignRequired(user);
        if (!documents?.length) return [];

        const today = new Date();
        const upperLimit = format(today, 'yyyy-MM-dd');
        const lowerLimit = format(sub(today, { days: 30 }), 'yyyy-MM-dd');

        const jobAgenda = await getJobAgenda(lowerLimit, upperLimit);
        return determineIfAnyJobIsInProgress(jobAgenda.jobs) ? [] : documents;
    });
