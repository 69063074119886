import { TextInput, Toggle } from '@HometreeEngineering/component-library';
import { Dispatch, SetStateAction } from 'react';
import style from './index.module.scss';

interface Props {
    handleCompanyNameOnChange: Dispatch<SetStateAction<string>>;
    handleSetIsVatRegistered: Dispatch<SetStateAction<boolean>>;
    handleVatOnChange: Dispatch<SetStateAction<string>>;
    companyValue: string;
    isVatRegistered: boolean;
    vatValue: string;
}

const Terms = ({
    handleCompanyNameOnChange,
    handleSetIsVatRegistered,
    handleVatOnChange,
    companyValue,
    isVatRegistered,
    vatValue,
}: Props) => {
    return (
        <section className={style.company_name}>
            <h2>Your Details</h2>
            <div>
                <TextInput
                    label="Company name"
                    id="company-name"
                    value={companyValue}
                    placeholder="Company name"
                    onChange={handleCompanyNameOnChange}
                    className={style.input}
                    enableAutoFocus
                />
            </div>
            <div className={style.vat_container}>
                <h3>Are you VAT registered? </h3>

                <Toggle
                    onChange={() => handleSetIsVatRegistered(!isVatRegistered)}
                    checked={isVatRegistered}
                    activeText="Yes"
                    inactiveText="No"
                />
            </div>
            {isVatRegistered && (
                <div className={style.field}>
                    <TextInput
                        label="VAT number"
                        id="vat-number"
                        testId="vat-number"
                        placeholder="VAT number"
                        value={vatValue}
                        onChange={handleVatOnChange}
                    />
                </div>
            )}
        </section>
    );
};

export default Terms;
