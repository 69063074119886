import {
    COST_OF_JOB_PER_HOUR,
    DEFAULT_ESTIMATED_DURATION,
    DEFAULT_ESTIMATED_PARTS_COST,
} from 'src/constants';
import { FormState, Part } from 'src/features/closeJob/types/Form';
import { jobTypeMap } from 'src/features/newClaim/data/jobTypeMap';
import { appliancesTypes } from 'src/types/Appliances';
import { JobType } from 'src/types/Job';
import { JobUrgency } from '../types/JobUrgency';
import { diffDateDays } from './dates';

export const HAS_SERVICE_ANSWER = 'I am not affected by any of the above';

const { NORMAL, URGENT, CRITICAL } = JobUrgency;

export function calculateJobEndDateRange(range = 30) {
    const RANGE_IN_MILLIS = range * 24 * 60 * 60 * 1000;
    return new Date(Date.now() - RANGE_IN_MILLIS);
}

export function getMostRecentReattendJobName(jobs, jobType) {
    const jobEndDateRange = calculateJobEndDateRange();
    const onlyLastMonthJobs = ({ node }) => node.ActualEnd > jobEndDateRange.toISOString();

    const byMostRecentFirst = (a, b) => {
        const bTime = new Date(b.node.ActualEnd).getTime();
        const aTime = new Date(a.node.ActualEnd).getTime();

        return bTime - aTime;
    };

    const onlyCompletedJobs = ({ node }) => node.JobStatus === 'Complete';
    const onlyReattends = ({ node }) =>
        node.Type === jobType && diffDateDays(new Date(node.ActualEnd), new Date(Date.now())) < 31;

    const jobName = ({ node }) => node.Name;

    const jobNames = jobs
        .filter(onlyLastMonthJobs)
        .sort(byMostRecentFirst)
        .filter(onlyCompletedJobs)
        .filter(onlyReattends)
        .map(jobName);

    return jobNames[0] || null;
}

export const essentialApplianceTypes = new Set([
    appliancesTypes.Oven,
    appliancesTypes['Oven Electric'],
    appliancesTypes['Oven Gas'],
    appliancesTypes.Cooker,
    appliancesTypes['Cooker Electric'],
    appliancesTypes['Cooker Gas'],
    appliancesTypes['Cooker Dual'],
    appliancesTypes.Freezer,
    appliancesTypes.Fridge,
    appliancesTypes['Fridge Freezer'],
    appliancesTypes.Hob,
    appliancesTypes['Hob Electric'],
    appliancesTypes['Hob Gas'],
]);

export const isEssentialAppliance = (jobType: string): boolean => {
    return essentialApplianceTypes.has(jobType);
};

// This is temporarily not considering critical jobs until we implement BNW-1133
// if ((isVulnerable || noService) && !(isVulnerable && noService)) {
export const determineJobUrgency = (
    vulnerable: string | undefined,
    service: string | undefined,
    insecureProperty: string | undefined,
    lockedOutFromProperty: string | undefined,
    doorsSecureBuilding: string | undefined,
    jobType: JobType,
    isApplianceUrgency?: string
) => {
    const isVulnerable = vulnerable === 'Yes';
    const noService = service && service !== HAS_SERVICE_ANSWER;
    const isPropertyInsecure = insecureProperty === 'Yes';
    const isLockedOut = lockedOutFromProperty === 'Yes';
    const isBuildingInsecure = doorsSecureBuilding === 'No';
    const isAppliance = jobTypeMap.appliances.includes(jobType);
    const isUrgentApplianceIssue = isApplianceUrgency === 'Yes';
    const isEssential = isEssentialAppliance(jobType);

    if (isEssential && isUrgentApplianceIssue && isVulnerable) {
        return CRITICAL;
    }

    if (isEssential && isUrgentApplianceIssue && !isVulnerable) {
        return URGENT;
    }

    if (isEssential && !isUrgentApplianceIssue && isVulnerable) {
        return URGENT;
    }

    if (isAppliance) {
        return NORMAL;
    }

    if (isVulnerable && noService) {
        return CRITICAL;
    }

    if (isVulnerable && isPropertyInsecure) {
        return CRITICAL;
    }

    if (isVulnerable && isLockedOut) {
        return CRITICAL;
    }

    if (isVulnerable && isBuildingInsecure) {
        return CRITICAL;
    }

    return isVulnerable || noService ? URGENT : NORMAL;
};

export const calculateLabourCost = (additionalHours: number | undefined): number => {
    const ADDITIONAL_HOURS_RATE = 50;
    return ADDITIONAL_HOURS_RATE * (additionalHours || 0);
};

export const calculateTotalCost = (
    additionalHours: number | undefined,
    partsCost: number | undefined
): number => calculateLabourCost(additionalHours) + (partsCost || 0);

export const isTooManyVisits = (previousEngineerVisits: number): boolean => {
    const PREVIOUS_VISITS_LIMIT = 1;

    return previousEngineerVisits > PREVIOUS_VISITS_LIMIT;
};

export const isEstimatedCostConfirmationRequired = (
    estimatedHoursNeeded: number | undefined,
    estimatedPartsCost: number | undefined
): boolean => {
    const ESTIMATED_COST_CONFIRMATION_LIMIT = 100;

    const totalCost = calculateTotalCost(estimatedHoursNeeded, estimatedPartsCost);
    return totalCost > ESTIMATED_COST_CONFIRMATION_LIMIT;
};

// TODO not used
export const isCostAuthorisedForFollowOn = (
    additionalHours: number | undefined,
    partsNetAmount: number | undefined,
    jobType: JobType
): boolean => {
    const totalCost = calculateTotalCost(additionalHours, partsNetAmount);

    switch (jobType) {
        case JobType.GAS_JOB:
            return totalCost <= 100;
        case JobType.DRAINS:
        case JobType.PLUMBING:
        case JobType.ELECTRIC:
            return totalCost <= 100;
        case JobType.GAS_JOB_REATTEND:
        case JobType.ANNUAL_SERVICE:
        default:
            return false;
    }
};

export const issueAreaCoveredInPolicy = (
    issueArea: string,
    package_bundle_name: string | undefined
): boolean => {
    const dropdownOptions = [
        'Boiler',
        'Flue',
        'Controls (eg. programmers, thermostats, zone valves, heating pumps)',
        'Gas supply pipe',
        'Wider central heating (eg. radiators and valves, expansion tanks, heating pipes)',
        'Hot water cylinders and immersion heaters',
        'Plumbing (excluding taps and showers)',
        'Drains',
        'Mains electrical system and wiring',
        'Taps',
        'Showers',
        'Other',
    ];

    const yourBoilerAreas = [
        'Boiler',
        'Flue',
        'Controls (eg. programmers, thermostats, zone valves, heating pumps)',
        'Gas supply pipe',
    ];

    const yourHeatingAreas = [
        ...yourBoilerAreas,
        'Wider central heating (eg. radiators and valves, expansion tanks, heating pipes)',
        'Hot water cylinders and immersion heaters',
    ];

    const yourHomeAreas = [
        ...yourHeatingAreas,
        'Plumbing (excluding taps and showers)',
        'Drains',
        'Mains electrical system and wiring',
    ];

    const yourHomePlusAreas = [...yourHomeAreas, 'Taps'];

    const yourSmartCoverAreas = [...yourHomePlusAreas, 'Showers'];

    // If any other issue area is selected, then the policy does not cover it.
    if (!dropdownOptions.includes(issueArea) || !package_bundle_name) {
        return false;
    }

    if (package_bundle_name === 'your-boiler' && yourBoilerAreas.includes(issueArea)) {
        return true;
    }

    if (package_bundle_name === 'your-heating' && yourHeatingAreas.includes(issueArea)) {
        return true;
    }

    if (package_bundle_name === 'your-home' && yourHomeAreas.includes(issueArea)) {
        return true;
    }

    if (package_bundle_name === 'your-home-plus' && yourHomePlusAreas.includes(issueArea)) {
        return true;
    }

    if (package_bundle_name === 'smart-cover' && yourSmartCoverAreas.includes(issueArea)) {
        return true;
    }

    if (issueArea === 'Other') {
        return false;
    }

    return false;
};

// this q is only for scheduling a followon
export const computeNextStepAfterActualHours = (
    formState: Partial<FormState>,
    packageName: string
): string => {
    if (formState.isResolved) {
        return 'complete';
    }

    if (formState.partsSource === 'canFitPartsAtNextVisit') {
        return 'nextVisitScheduled';
    }

    if (
        (formState.issueCauses &&
            formState.issueCauses.length > 0 &&
            formState.issueCauses.some((cause) => cause.toLowerCase() !== 'none')) ||
        (formState.confidenceInDiagnosis && formState.confidenceInDiagnosis <= 1) ||
        (formState.issueArea && !issueAreaCoveredInPolicy(formState.issueArea, packageName))
    ) {
        return 'rejectionNotice';
    }
    return 'investigationRequired';
};

export const calculateActualPartsCostLimit = (
    estimatedPartsCost: number | null | undefined,
    followOnEstimatedPartsCost: number | undefined,
    isSameJob: boolean
) => {
    const partsCost = estimatedPartsCost || DEFAULT_ESTIMATED_PARTS_COST;

    if (isSameJob) {
        return partsCost + (followOnEstimatedPartsCost || 0);
    }

    return partsCost;
};

export const calculateActualHoursLimit = (
    estimatedDuration: number | null | undefined,
    followOnEstimatedDuration: number | undefined,
    isSameJob: boolean
) => {
    const duration = estimatedDuration || DEFAULT_ESTIMATED_DURATION;

    if (isSameJob) {
        return duration + (followOnEstimatedDuration || 0);
    }
    return duration;
};

export const abbreviateJobSummary = (summary: string) => {
    const jobSummary = summary.split(' ').slice(0, 5);
    return `${jobSummary.join(' ')}${jobSummary.length === 5 ? '...' : ''}`;
};

// Structured parts job utils
export const labourHoursToCost = (labourInHours: number) => COST_OF_JOB_PER_HOUR * labourInHours;

export const totalCostOfJob = (labourInHours: number, partsCost: number) =>
    partsCost + labourHoursToCost(labourInHours);

export const totalEstimatedCostOfParts = (parts: Part[]) =>
    parts.reduce((accumulator, item) => accumulator + (item.estimatedCost || 0), 0);
