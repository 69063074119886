import { JobType } from 'src/types/Job';
import { determineJobUrgency, isEssentialAppliance } from 'src/utils/jobs';
import { FormState } from '../types/Form';
import { QuestionStage, QuestionType } from '../types/Question';
import { checkIsAllowedJobType } from '../utils/checkIsAllowedJobType';
import { determineApplianceAge } from '../utils/determineContributionAmount';
import { getAppliance } from '../utils/getAppliance';
import { hasOpenJobSameType } from '../utils/hasOpenJobSameType';

export const appliancesQuestions = (_formState: Partial<FormState>): QuestionStage[] => {
    const applianceDetails = getAppliance(_formState);
    return [
        {
            handle: 'appliancesGroup1',
            questions: [
                {
                    name: 'jobType',
                    title: 'Your appliance cover',
                    description: 'Please select the faulty appliance',
                    type: QuestionType.CATEGORIES,
                    isRequired: true,
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    jobType: values.jobType,
                    description: values.description,
                    applianceId: values.applianceId,
                } as Partial<FormState>;

                return formState;
            },
            options: [
                {
                    copy: 'Back',
                    nextQuestion: 'problemAreaNew',
                    variant: 'outlined',
                },
                {
                    copy: 'Next',
                    isDisabled: (values) => !values.jobType || !values.description,
                    nextQuestion: (values) => {
                        if (
                            typeof values.jobType === 'string' &&
                            _formState?.jobs?.length &&
                            hasOpenJobSameType(_formState?.jobs, values.jobType)
                        ) {
                            return 'sameJobTypeAlreadyOpen';
                        }
                        return 'appliancesGroup2';
                    },
                },
            ],
        },
        {
            handle: 'appliancesGroup2',
            questions: [
                {
                    name: 'applianceMaker',
                    title: 'Repair Details',
                    description: 'What is the make of your appliance?',
                    type: QuestionType.DROPDOWN_SEARCH,
                    isDisabled: !!applianceDetails?.make,
                    isRequired: true,
                    config: {
                        placeholder: `${
                            applianceDetails?.make ? applianceDetails.make : 'Search...'
                        }`,
                        icon: true,
                    },
                },
                {
                    name: 'applianceModel',
                    description: 'What is the model of your appliance?',
                    type: QuestionType.INPUT_TEXT_NEW,
                    isRequired: true,
                    isDisabled: !!applianceDetails?.modelNumber,
                    config: {
                        placeholder: `${
                            applianceDetails?.modelNumber
                                ? applianceDetails.modelNumber
                                : 'Enter information here'
                        }`,
                    },
                },
                {
                    name: 'jobType',
                    description: 'What fuel does the appliance use?',
                    type: QuestionType.DROPDOWN_NEW,
                    isRequired: true,
                    config: {
                        placeholder: 'Please select an option',
                    },
                },
                {
                    name: 'appliancePurchase',
                    description: 'When did you purchase your appliance?',
                    type: QuestionType.DROPDOWN_CALENDAR,
                    isRequired: true,
                    isDisabled: !!applianceDetails?.purchaseDate,
                    config: {
                        placeholder: applianceDetails.purchaseMonth || 'Month',
                        secondPlaceholder: applianceDetails.purchaseYear || 'Year',
                    },
                },
                {
                    name: 'applianceFaultCode',
                    description: 'Is there any fault code on the appliance?',
                    type: QuestionType.INPUT_TEXT_NEW,
                    isRequired: false,
                    config: {
                        placeholder: 'Enter information here',
                    },
                },
                {
                    name: 'applianceSerialNumber',
                    description: 'What is the serial number of the appliance?',
                    type: QuestionType.INPUT_TEXT_NEW,
                    isDisabled: !!applianceDetails?.serialNumber,
                    isRequired: false,
                    config: {
                        placeholder: applianceDetails?.serialNumber || 'Enter information here',
                    },
                },
            ],
            onSubmit: (values: any) => {
                let purchaseDate;
                if (values.appliancePurchaseMonth || values.appliancePurchaseYear) {
                    purchaseDate = `${values.appliancePurchaseYear.name}-${
                        values.appliancePurchaseMonth.id ? values.appliancePurchaseMonth.id : ''
                    }`;
                }

                const calculatedApplianceAge = determineApplianceAge(
                    purchaseDate || applianceDetails.purchaseDate
                );

                const newState: Partial<FormState> = {
                    applianceMaker: values.applianceMaker || applianceDetails?.make,
                    applianceModel: values.applianceModel || applianceDetails?.modelNumber,
                    applianceFaultCode: values.applianceFaultCode || '',
                    applianceSerialNumber:
                        values.applianceSerialNumber || applianceDetails?.serialNumber,
                    appliancePurchaseDate: purchaseDate
                        ? new Date(purchaseDate).toISOString()
                        : applianceDetails?.purchaseDate,
                    applianceAge: calculatedApplianceAge,
                    applianceMakeId: values.applianceMakeId,
                };

                if (values.jobType) {
                    newState.jobType = values.jobType;
                }

                return newState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (
                            typeof values.jobType === 'string' &&
                            values?.jobs?.length &&
                            hasOpenJobSameType(values?.jobs, values.jobType)
                        ) {
                            return 'sameJobTypeAlreadyOpen';
                        }
                        const isEssential =
                            typeof _formState.jobType === 'string' &&
                            isEssentialAppliance(_formState.jobType);

                        if (isEssential) {
                            return 'applianceVulnerability';
                        }

                        return 'vulnerability';
                    },
                    isDisabled: (values: any, jobType?: string) => {
                        const purchaseDate = `${
                            values.appliancePurchaseYear?.name
                                ? values.appliancePurchaseYear.name
                                : ''
                        }-${
                            values.appliancePurchaseMonth?.id
                                ? values.appliancePurchaseMonth.id
                                : ''
                        }`;
                        return (
                            !(values.applianceMaker || applianceDetails?.make) ||
                            !(values.applianceModel || applianceDetails?.modelNumber) ||
                            !(values.appliancePurchaseMonth || applianceDetails.purchaseMonth) ||
                            !(values.appliancePurchaseYear || applianceDetails.purchaseYear) ||
                            !checkIsAllowedJobType(values.jobType || jobType) ||
                            new Date(purchaseDate) > new Date()
                        );
                    },
                },
            ],
        },
        {
            handle: 'applianceVulnerability',
            questions: [
                {
                    name: 'vulnerable',
                    description:
                        'Please let us know if there are vulnerable people at the property?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
                {
                    name: 'applianceUrgencyIssue',
                    description:
                        'Does this issue prevent you from storing essentials (e.g. medication) or preparing meals?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
            ],
            onSubmit: (values: Partial<FormState>, submitFormState) => ({
                vulnerable: values.vulnerable,
                urgency: determineJobUrgency(
                    String(values.vulnerable),
                    submitFormState.problemType || '',
                    submitFormState.insecureProperty || '',
                    submitFormState.lockedOutFromProperty || '',
                    submitFormState.doorsSecureBuilding || '',
                    submitFormState.jobType as JobType,
                    values?.applianceUrgencyIssue || ''
                ),
            }),
            options: [
                {
                    copy: 'Next',
                    nextQuestion: 'chooseDate',
                },
            ],
        },
    ];
};
