import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorBar from 'src/components/ErrorBar';
import Layout from 'src/components/Layout';
import Loader from 'src/components/Loader';
import { HOMETREE_PHONE_NUMBER } from 'src/constants';
import { useAppContext } from 'src/contexts/AppContext';
import { Account } from 'src/features/login/api/account.types';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';
import { useCreateClaim } from 'src/hooks/useCreateClaim';
import { useGetContractsQuery } from 'src/hooks/useGetContractQuery';
import { useHandleNewClaimErrors } from 'src/hooks/useHandleNewClaimErrors';
import { useUpdateAppliance } from 'src/hooks/useUpdateAppliance';
import { AppliancePayload } from 'src/types/Appliances';
import { CustomerData } from 'src/types/CustomerData';
import { AreaOfIssue } from 'src/types/Job';
import { Routes } from 'src/types/Routes';
import { newAnalyticsEvent, newVirtualPageView } from 'src/utils/analytics';
import { getMandatoryEnvVar } from 'src/utils/env';
import { errorToJson } from 'src/utils/error';
import { mixpanelTrackPage } from 'src/utils/mixpanel';
import { getUserModeId } from 'src/utils/userMode';
import { questionStages } from '../../config/questions';
import { jobTypeMap } from '../../data/jobTypeMap';
import { END_STAGE_HANDLES, INITIAL_STAGE_HANDLE } from '../../data/stageConfig';
import { FormState, Reattend } from '../../types/Form';
import { buildCustomerData } from '../../utils/buildCustomerData';
import { determineApplianceDataToUpdate } from '../../utils/determineApplianceDataToUpdate';
import { getMostRecentReattendJobName } from '../../utils/jobs';
import { mapFormToRequestBody } from '../../utils/mapFormToRequestBody';
import { trackFnolDataWrapper } from '../../utils/trackFnolDataWrapper';
import Form from '../Form';
import styles from './index.module.scss';

interface NewClaimProps {
    accountData: Account;
}

const NewClaim = ({ accountData }: NewClaimProps) => {
    const {
        state: { paramUserMode },
        dispatch,
    } = useAppContext();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [newClaimError, setNewClaimError] = useState('');
    const [fnolTrackingId, setFnolTrackingId] = useState<number | null>(null);
    const [formState, setFormState] = useState<Partial<FormState>>({});
    const [reattend, setReattend] = useState<Reattend>(Reattend.NO);
    const [relatedJob, setRelatedJob] = useState<string | null>(null);

    const { mutateAsync: createClaim, isError, error: claimCreateError } = useCreateClaim();
    const enabledAppliances = getMandatoryEnvVar('REACT_APP_APPLIANCES_ENABLED');
    const bookingToolWarningEnabled = getMandatoryEnvVar('REACT_APP_BOOKING_TOOL_WARNING_ENABLED');
    const bookingToolWarningText = getMandatoryEnvVar('REACT_APP_BOOKING_TOOL_WARNING_TEXT');
    const defaultErrorMessage = `We're having trouble getting your contract information. If this message persists please call ${HOMETREE_PHONE_NUMBER}`;

    const { mutate: updateAppliance } = useUpdateAppliance();

    const {
        data: allContractData,
        isLoading: isContractLoading,
        isError: isContractError,
        errors: contractErrors,
    } = useGetContractsQuery(accountData);

    const customerData = buildCustomerData(accountData, allContractData);

    useHandleNewClaimErrors(
        formState,
        customerData,
        allContractData,
        setNewClaimError,
        claimCreateError,
        isError,
        accountData,
        isContractLoading,
        contractErrors
    );

    useEffect(() => {
        if (formState.contractId) {
            const contractForPayments = allContractData?.find(
                (contract) => contract.id === formState.contractId
            );

            if (contractForPayments?.contractCustomerFacingId) {
                newVirtualPageView('FNOL', '/vpv/fnol');
                newAnalyticsEvent({
                    category: 'FNOL',
                    action: 'Landed',
                    label: contractForPayments.contractCustomerFacingId,
                    value: getUserModeId(paramUserMode),
                });
            }

            mixpanelTrackPage();

            if (contractForPayments?.jobs) {
                const appliancesTypes =
                    formState.jobType === AreaOfIssue.APPLIANCES && jobTypeMap[formState.jobType];
                const jobTypeMapped = jobTypeMap[formState.jobType || ''];

                const mostRecentReattendJobName = getMostRecentReattendJobName(
                    contractForPayments?.jobs || [],
                    jobTypeMapped,
                    appliancesTypes
                );

                setRelatedJob(mostRecentReattendJobName);
                setReattend(mostRecentReattendJobName ? Reattend.YES : Reattend.NO);
            }
        }
    }, [
        formState.contractId,
        customerData?.contracts,
        allContractData,
        formState.jobType,
        paramUserMode,
    ]);

    if (isContractLoading) {
        return <Loader />;
    }

    if (newClaimError || isContractError) {
        return (
            <ErrorBar
                message={newClaimError || (isContractError ? defaultErrorMessage : '')}
                style={styles.claimFormErrorBar}
            />
        );
    }

    if (customerData === null && !isContractLoading) {
        return <ErrorBar message={defaultErrorMessage} style={styles.claimFormErrorBar} />;
    }

    const onSubmit = async (submitFormState: Partial<FormState>) => {
        setIsSubmitting(true);

        try {
            newAnalyticsEvent({
                category: 'FNOL',
                action: 'Clicked create job',
                label: formState?.customerFacingId,
                value: getUserModeId(paramUserMode),
            });

            const body = mapFormToRequestBody({
                formState: submitFormState,
                relatedJob,
                customerData: customerData as CustomerData,
                reattend,
                userMode: paramUserMode,
            });
            const createClaimResult = await createClaim(body);

            const { appliances, packageDetails } = formState;

            const getClaimAppliance = appliances?.filter(
                (appliance) => appliance.id === submitFormState.applianceId
            );

            if (getClaimAppliance && getClaimAppliance.length > 0) {
                const claimAppliance = getClaimAppliance[0];
                const dataToUpdate = determineApplianceDataToUpdate(
                    submitFormState,
                    claimAppliance,
                    appliances
                ) as AppliancePayload;
                if (Object.keys(dataToUpdate).length > 1) {
                    await updateAppliance(dataToUpdate);
                }
            }

            const newAppState = {
                ...body,
                packageDetails,
                skeduloJobDetails: {
                    jobs: {
                        edges: [
                            {
                                node: {
                                    Name: createClaimResult.jobName,
                                },
                            },
                        ],
                    },
                },
            };

            dispatch({
                type: 'setState',
                payload: {
                    ...newAppState,
                },
            });

            if (formState.contractId) {
                const dataToTrack = {
                    contractId: formState?.contractId,
                    skJobUid: createClaimResult.jobUid,
                    userMode: getUserModeId(paramUserMode),
                };
                await trackFnolDataWrapper(dataToTrack, setFnolTrackingId, fnolTrackingId);
            }

            navigate(Routes.CLAIM_CONFIRMATION);
        } catch (e) {
            captureSentryError(e, {
                message: `Error creating claim`,
                contractId: formState.contractId || 'No Contract Id found',
                errorStringified: JSON.stringify(errorToJson(e)),
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            {bookingToolWarningEnabled === 'true' && (
                <div
                    className={styles.banner}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: bookingToolWarningText,
                    }}
                />
            )}
            {contractErrors.length && allContractData?.length ? (
                <ErrorBar
                    message={contractErrors.map((err) => err).join(', ')}
                    style={styles.claimFormErrorBar}
                />
            ) : null}
            {customerData && !isContractLoading && (
                <Layout>
                    <Form
                        questionStages={questionStages(customerData, formState, reattend)}
                        initialStageHandle={
                            enabledAppliances === 'true' ? 'problemAreaNew' : INITIAL_STAGE_HANDLE
                        }
                        endStageHandles={END_STAGE_HANDLES}
                        formSubmit={onSubmit}
                        isFormSubmitting={isSubmitting}
                        customerData={customerData}
                        userMode={paramUserMode}
                        trackFnolData={(data) =>
                            trackFnolDataWrapper(data, setFnolTrackingId, fnolTrackingId)
                        }
                        formState={formState}
                        setFormState={setFormState}
                    />
                </Layout>
            )}
        </>
    );
};

export default NewClaim;
