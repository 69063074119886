import { Button, Checkbox } from '@HometreeEngineering/component-library';
import HelloSign from 'hellosign-embedded';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { State } from 'src/App';
import Layout from 'src/components/Layout';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';
import { getMandatoryEnvVar } from 'src/utils/env';
import { Document } from '../api/getDocumentsSignRequired';
import { useCreateEmbeddedLink } from '../hooks/useCreateEmbeddedLink';
import style from './index.module.scss';
import Terms from './Terms';

const CLIENT_ID = getMandatoryEnvVar('REACT_APP_DROPBOX_SIGN_CLIENT_ID');

interface Props {
    setState: React.Dispatch<React.SetStateAction<State>>;
    documents: Document[];
}

const TermsAndConditions = ({ setState, documents }: Props) => {
    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState('');
    const [isVatRegistered, setIsVatRegistered] = useState(false);
    const [vatNumber, setVatNumber] = useState('');
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [vatInputError, setVatInputError] = useState(false);
    const [showError, setShowError] = useState(false);

    const { mutate, data, isLoading, isError } = useCreateEmbeddedLink(documents);

    // TODO clean up after using to open?
    const embeddedLink = useMemo(() => data?.embeddedLink, [data]);

    const signDocument = useCallback(async () => {
        const client = new HelloSign();
        client.open(embeddedLink, {
            clientId: CLIENT_ID,
            skipDomainVerification: true,
        });
        client.on('sign', () => {
            setState((prevState) => ({ ...prevState, justSignedDocsOn: new Date().toISOString() }));
            navigate(localStorage.getItem('redirectAfterSign') || '/');
            localStorage.removeItem('redirectAfterSign');
        });
    }, [embeddedLink, navigate, setState]);

    useEffect(() => {
        if (data) {
            signDocument();
        }
    }, [data, signDocument]);

    const onSubmit = async () => {
        try {
            if (!vatNumber.match(/^GB\d{9}$/)) {
                setVatInputError(true);
                return;
            }
            setShowError(false);
            await mutate({ companyName, vatNumber });
            setVatInputError(false);
        } catch (error) {
            await captureSentryError(error, {
                message: 'Unable to get embedded link',
                companyName,
                vatNumber,
            });
            setVatInputError(false);
            setShowError(true);
        }
    };

    return (
        <Layout>
            <article className={style.container}>
                <Terms
                    handleCompanyNameOnChange={setCompanyName}
                    handleSetIsVatRegistered={setIsVatRegistered}
                    handleVatOnChange={setVatNumber}
                    companyValue={companyName}
                    isVatRegistered={isVatRegistered}
                    vatValue={vatNumber}
                />
                <section className={style.submitContainer}>
                    {(isError || showError) && (
                        <p className={style.errorText} data-testid="api-error">
                            There was an error getting your documents. Please try again later or
                            contact us if problem persists
                        </p>
                    )}
                    {vatInputError && (
                        <p className={style.errorText} data-testid="vat-error">
                            VAT number must start with GB followed by 9 digits
                        </p>
                    )}
                    <Checkbox
                        id="confirm-authorisation"
                        checked={isCheckboxChecked}
                        testId="confirm-authorisation"
                        onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                    >
                        <p>
                            I confirm the above information is accurate and will inform Hometree
                            Group of any changes.
                        </p>
                    </Checkbox>
                    <Button
                        customStyle={style.submitButton}
                        onClick={onSubmit}
                        isDisabled={
                            isLoading ||
                            companyName === '' ||
                            (isVatRegistered && !vatNumber) ||
                            !isCheckboxChecked
                        }
                    >
                        Next
                    </Button>
                </section>
            </article>
        </Layout>
    );
};

export default TermsAndConditions;
