import { FnolTrackingRequestBody, trackFnolData } from '../api/fnolTracking';

export const trackFnolDataWrapper = async (
    data: FnolTrackingRequestBody,
    setFnolTrackingId?: React.Dispatch<React.SetStateAction<number | null>>,
    fnolTrackingId?: number | null
) => {
    const newFnolTrackingId = await trackFnolData({
        id: fnolTrackingId || undefined,
        ...data,
    });

    if (newFnolTrackingId instanceof Error) return;

    if (fnolTrackingId !== newFnolTrackingId && setFnolTrackingId) {
        setFnolTrackingId(newFnolTrackingId);
    }
};
