import { INTEGRATION_ENDPOINT } from 'src/constants';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';
import { getMandatoryEnvVar } from 'src/utils/env';
import { errorToJson } from 'src/utils/error';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

// eslint-disable-next-line import/prefer-default-export
export const createPaymentMethod = async (
    contractId: number,
    paymentAmount: number,
    updateNewClaimError: (message: string) => void
) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            contractId,
            paymentAmount,
        }),
    };

    try {
        const response = await fetch(
            `${API_BASE_URL}/${INTEGRATION_ENDPOINT}/stripe/createPayment`,
            requestOptions
        );
        const data = await response.json();

        if (!response.ok) {
            const error = (data && data.message) || response.status;
            throw new Error(error);
        }

        return data.data.paymentIntent.client_secret;
    } catch (error) {
        const errorMessage =
            'Something went wrong while trying to create a payment. Please try again later or give us a call.';
        // eslint-disable-next-line no-console
        console.error(error.message, JSON.stringify(error));
        captureSentryError(error, {
            message: 'Error creating payment for claim',
            errorStringified: JSON.stringify(errorToJson(error)),
        });
        updateNewClaimError(errorMessage);
        throw new Error(errorMessage);
    }
};
