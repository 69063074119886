import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import Button from 'src/components/Button';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';
import { Option, QuestionStage, QuestionStageValues } from '../../types/Question';
import styles from './PaymentElementWrapper.module.scss';

type Props = {
    clientSecret: string;
    stripeError: string;
    setStripeError: (error: string) => void;
    onOptionClick: (option: Option) => () => void;
    currentStage: QuestionStage;
    setState: (state: QuestionStageValues) => void;
    currentStageState: QuestionStageValues;
};

const PaymentElementWrapper = ({
    clientSecret,
    stripeError,
    setStripeError,
    onOptionClick,
    currentStage,
    setState,
    currentStageState,
}: Props) => {
    const stripe = useStripe();
    const elements = useElements();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            // Set the payment intent ID in the state to be charged by the backend
            if (
                currentStageState &&
                !currentStageState.paymentId &&
                paymentIntent &&
                paymentIntent.id
            ) {
                setState({ paymentId: paymentIntent.id });
            }
        });
    }, [clientSecret, stripe, setState, currentStageState]);

    const handleSubmit = (option: Option) => async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            await captureSentryError(
                new Error(
                    'stripe or elements has not fully loaded yet and form has been submitted'
                ),
                {
                    stripeLoaded: !!stripe,
                    elementsLoaded: !!elements,
                }
            );

            return;
        }

        setIsLoading(true);

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: 'http://localhost:3000',
            },
            redirect: 'if_required',
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (
            error &&
            error.message &&
            (error.type === 'card_error' || error.type === 'validation_error')
        ) {
            setStripeError(error.message);
        } else if (paymentIntent && paymentIntent.status === 'requires_capture') {
            await onOptionClick(option)();
        } else {
            // eslint-disable-next-line no-console
            console.error(error);
            setStripeError('An unexpected error occurred.');
        }

        setIsLoading(false);
    };

    if (stripeError) {
        return <div className="fnolform-error">{stripeError}</div>;
    }

    return (
        <>
            <div data-testid="stripe-card-element">
                <PaymentElement id="payment-element" />
            </div>

            <div className={styles.optionsContainer}>
                {(currentStage.options || []).map((eachOption) => {
                    return (
                        <Button
                            key={`option-${eachOption.copy}`}
                            isDisabled={isLoading}
                            type="button"
                            onClick={handleSubmit(eachOption)}
                        >
                            {eachOption.copy}
                        </Button>
                    );
                })}
            </div>
        </>
    );
};

export default PaymentElementWrapper;
