import { Routes as RoutePath } from '../types/Routes';

export const isNotOnDocumentsPage = (pathname: string) => {
    return pathname !== RoutePath.TERMS_AND_CONDITIONS;
};

export const justSignedDocs = (signedOn: string | undefined): boolean => {
    if (!signedOn) return false;
    if (signedOn === '') return false;
    if (Number.isNaN(new Date(signedOn).getTime())) return false;

    const now = new Date();
    const signedOnDate = new Date(signedOn);
    const diff = (now.getTime() - signedOnDate.getTime()) / 1000;

    if (diff > 300) return false;

    return true;
};
