import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthenticatedUser } from 'src/hooks/useAuthenticatedUser';
import { Role } from 'src/types/Role';
import { Account, Boiler, Calendar, Repair } from '../../images';
import { Routes } from '../../types/Routes';
import NavbarLogout from '../logout/components/NavBarLogout';
import NavbarIcon from './NavBarIcon';
import styles from './index.module.scss';

const NavBar = () => {
    const { data } = useAuthenticatedUser();
    const { pathname } = useLocation();

    const getNavBarIcons = useCallback(() => {
        const role = data?.authenticatedUser?.attributes['custom:role'] as Role;
        switch (role) {
            case Role.CUSTOMER:
                return (
                    <>
                        <NavbarIcon
                            text="Book"
                            link={Routes.CLAIM_NEW}
                            selected={pathname === Routes.CLAIM_NEW}
                            reloadDocument
                        >
                            <Calendar />
                        </NavbarIcon>
                        <NavbarIcon
                            text="Annual Service"
                            link="https://www.hometree.co.uk/help-centre/covid-19/when-will-my-annual-service-visit-take-place"
                            selected={false}
                            target="_blank"
                            absolute
                        >
                            <Boiler />
                        </NavbarIcon>
                        <NavbarIcon
                            text="Account"
                            link={Routes.CUSTOMER_ACCOUNT}
                            selected={pathname === Routes.CUSTOMER_ACCOUNT}
                        >
                            <Account />
                        </NavbarIcon>
                    </>
                );
            case Role.ENGINEER:
                return (
                    <>
                        <NavbarIcon
                            text="Agenda"
                            link={Routes.JOB_AGENDA}
                            selected={pathname === Routes.JOB_AGENDA}
                        >
                            <Calendar />
                        </NavbarIcon>
                        <NavbarIcon
                            text="Offers"
                            link={Routes.JOB_OFFERS}
                            selected={pathname === Routes.JOB_OFFERS}
                        >
                            <Repair />
                        </NavbarIcon>
                        <NavbarIcon
                            text="Account"
                            link={Routes.ENGINEER_ACCOUNT}
                            selected={pathname.includes(Routes.ENGINEER_ACCOUNT)}
                        >
                            <Account />
                        </NavbarIcon>
                    </>
                );
            default:
                return (
                    <div>
                        <NavbarLogout />
                    </div>
                );
        }
    }, [data, pathname]);

    return (
        <div className={styles.container}>
            <div className={styles.routesContainer}>{getNavBarIcons()}</div>
        </div>
    );
};

export default NavBar;
